import { createContext } from "react";

export type MainContextProps = {
	menuOption?: string;
	setMenuOption?: (option: string) => void;
	kognifaiTags?: {};
	setKognifaiTags?: (tags: any) => void;
	location?: {};
};

export const MainContext = createContext<MainContextProps>({
	menuOption: "default",
	setMenuOption: () => {},
	kognifaiTags: {},
	setKognifaiTags: () => {},
	location: {},
});
