import React, { useEffect } from "react";
import { getErrorMessage } from "./api-server/usertoken";
import { logout } from "../utils/utils";

const LoginFailed = (props: any) => {
    useEffect(() => {
        if (
            localStorage.getItem(`LoginMessage`) &&
            localStorage.getItem(`LoginMessage`) !== undefined
        ) {
            logout(true);
        }
        if (!localStorage.getItem(`LoginMessage`)) {
            getErrorMessage()
                .then(async (res: any) => {
                    logout(true);
                })
                .catch((e: any) => {
                    //console.log("Error getting error message.", e);
                    logout(true);
                });
        }
        // logout(true);
    }, []);

    return <></>;
};

export default LoginFailed;
