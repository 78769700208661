/**
 * File to set containers and props on load
 */

import LookupManager from "../containers/LookupManager";
import GenericTabContainer from "../containers/GenericTabContainer";
import Default from "../containers/Default";
import Configuration from "../containers/Configuration";
// import Profile from "../containers/Profile";
import LeaveContainer from "../containers/LeaveContainer";
import AdministrationContainer from "../containers/AdministrationContainer";
import PoliciesContainer from "../containers/PoliciesContainer";
import AnnouncementContainer from "../containers/AnnouncementContainer";
// import UserRoleManagerContainer from "../containers/UserRoleManagerContainer";

export const containerList = [];

export const adminContainerList = [];

// Always add params, it is important for passing user & menu data
export const setContainer = (
	component: any,
	title: any,
	key: any,
	params: any,
	role: any,
	user: any
) => {
	switch (component) {
		case "default":
			return <Default params={params} />;
		case "configuration":
			return <Configuration params={params} />;
		// case "profile":
		//     return <Profile params={params} />;
		case "tabContainer":
			return <GenericTabContainer params={params} />;
		case "leaveContainer":
			return <LeaveContainer params={params} />;
		case "lookupManager":
			return <LookupManager params={params} />;
		case "administrationContainer":
			return <AdministrationContainer params={params} />;
		case "policiesContainer":
			return <PoliciesContainer params={params} />;
		case "announcementContainer":
			return <AnnouncementContainer params={params} />;
	}
};

export const setAdminContainer = (
	component: any,
	params: any | null = null
) => {
	switch (component) {
	}
};
