import React, { useContext } from "react";
import type { FormInstance } from "antd";
import { Button, Form, Input, Space } from "antd";
import { HomeContext } from "../containers/Home";

const FormButtonSave = ({
    form,
    handleFormSave,
    formReset,
}: {
    form: FormInstance;
    handleFormSave: any;
    formReset: any | null;
}) => {
    const [submittable, setSubmittable] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState<any>(null);
    const [disable, setDisable] = React.useState<boolean>(false);

    // Watch all values
    const values = Form.useWatch([], form);
    const context: any = useContext(HomeContext);

    React.useEffect(() => {
        // The state of formReset need to be false AND the initialValues need to exist in order to validate the form
        if (!formReset && initialValues) {
            const valuesChanged = Object.keys(values).some((key) => {
                if (typeof values[key] == "object") {
                    return (
                        JSON.stringify(values[key]) !==
                        JSON.stringify(initialValues[key])
                    );
                } else if (
                    typeof initialValues[key] === "number" &&
                    typeof values[key] !== "number"
                ) {
                    return values[key] !== JSON.stringify(initialValues[key]);
                } else {
                    return values[key] !== initialValues[key];
                }
            });
            if (valuesChanged) {
                // If values from the initial form changed, set the submittable to true
                form.validateFields({ validateOnly: true }).then(
                    () => {
                        context?.handleFormUpdating(true);
                        setSubmittable(true);
                        if (
                            (initialValues?.business_unit !==
                                values.business_unit ||
                                initialValues?.client !== values.client) &&
                            initialValues?.business_unit &&
                            initialValues?.client
                        ) {
                            setDisable(true);
                        } else {
                            setDisable(false);
                        }
                    },
                    () => {
                        context?.handleFormUpdating(true);
                        setSubmittable(false);
                    }
                );
            } else {
                context?.handleFormUpdating(false);
                setSubmittable(false);
            }
        } else {
            context?.handleFormUpdating(false);
            setSubmittable(false);
        }
    }, [values, formReset, initialValues]);

    React.useEffect(() => {
        // console.log("formReset", formReset);
        if (formReset) {
            if (values) {
                setInitialValues({ ...values });
            }
        }
    }, [values]);

    return (
        <Button
            onClick={() => {
                if (!disable) {
                    handleFormSave(values);
                }
            }}
            type="primary"
            htmlType="submit"
            disabled={!submittable}
        >
            Save
        </Button>
    );
};

export default FormButtonSave;
