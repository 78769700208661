import React, { useEffect, useState } from "react";
import { getLookupItem } from "../utils/lookup_list";
import {
  Modal,
  Form,
  Select,
  Button,
  Table,
  message,
  Space,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import RajangLogo from "../assets/image/rajang logo wb.png";
import { daysCalculation } from "../utils/utils";
import Emitter from "../utils/emitter";
import { CatchingPokemonSharp } from "@mui/icons-material";
import { NodeStyleEventEmitter } from "rxjs/internal/observable/fromEvent";
import { isMobile } from "react-device-detect";
import SearchButton from "../components/SearchButton";

const { Text } = Typography;

// Initiate a instance to get the current timezone
const moment_timezone = require("moment-timezone");

// Get current date and time in GMT+8 (Malaysia)
let currentDateTime = moment_timezone()
  .tz("Asia/Kuala_Lumpur")
  .format("DD-MM-YYYY HH:mm:ss A [GMT]Z");

// For modal close icon
const CustomCloseIcon = () => (
  <CloseOutlined style={{ fontSize: "20px", color: "white" }} />
);

// To list down the options for selecting the year or month
const { Option } = Select;

// Main Component
const LeaveReport = () => {
  // Hooks to obtain and access the database from the database
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [leaveData, setLeaveData] = useState<any>([]);
  const [leavetypeData, setLeavetypeData] = useState<any>([]);
  const [positionData, setPositionData] = useState<any>([]);
  // Hooks for monthly and annual form
  const [monthlyreportform] = Form.useForm();
  const [annualreportform] = Form.useForm();

  // Hooks for generating annual leave application report
  const [selectedEmployee, setSelectedEmployee] = useState<any>([]);
  const [annual_report_modal_visible, setAnnualReportModalVisible] =
    useState(false);
  const [annual_reports_modal_visible, setAnnualReportsModalVisible] =
    useState(false);
  const getCurrentYear = () => {
    return new Date().getFullYear().toString();
  };
  const [annualreportyear, setAnnualReportYear] = useState(getCurrentYear());

  // Hooks for generating monthly leave application report
  const [monthly_report_modal_visible, setMonthlyReportModalVisible] =
    useState(false);
  const [monthlyreportyear, setMonthlyReportYear] = useState(getCurrentYear());
  const getCurrentMonth = () => {
    return (new Date().getMonth() + 1).toString();
  }; // Adding 1 because getMonth() returns 0-based index
  const [monthlyreportmonth, setMonthlyReportMonth] = useState(
    getCurrentMonth()
  );
  const [searchRecord, setSearchRecord] = useState<any>([]);
  const [tempRecord, setTempRecord] = useState<any>(null);

  const handleSearch = (record: any) => {
    setTempRecord(record);
  };

  const [filteredDataTable, setFilteredDataTable] = useState<any>(null);

  //Hooks to obtain holiday and workday data

  const [holidays, setHolidays] = useState<any>(null);
  const [workdays, setWorkdays] = useState<any>(null);

  // rowSelection object
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // Function to capitalize the first letter of leaves
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  //To get holiday and workday data
  useEffect(() => {
    getLookupItem("workday").then((workday_data: any) => {
      getLookupItem("holiday").then((holiday_data: any) => {
        setHolidays(holiday_data);
        setWorkdays(workday_data);
      });
    });
  }, []);

  useEffect(() => {
    // console.log("tempRecord activated: ", tempRecord);
    if (searchRecord && tempRecord) {
      // console.log("11111", searchRecord, tempRecord);
      let recordIndex = searchRecord.findIndex((item: any) => {
        return item.columnKey === tempRecord.columnKey;
      });
      if (recordIndex !== -1) {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record[recordIndex] = tempRecord;
          setSearchRecord(_record);
        } else {
          let _record = [...searchRecord];
          _record.splice(recordIndex, 1);
          setSearchRecord(_record);
        }
      } else {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record.push(tempRecord);
          setSearchRecord(_record);
        }
      }
    }
  }, [tempRecord]);

  // To get updated date on render
  useEffect(() => {
    // Fetch data from the server

    // Get leave type data
    getLookupItem("leavetype")
      .then((data: any) => {
        setLeavetypeData(data);
      })
      .catch((error: any) => {
        console.error("Error fetching leave type data: ", error);
      });

    // Get leave application data
    getLookupItem("leave")
      .then((data: any) => {
        setLeaveData(data);
      })
      .catch((error: any) => {
        console.error("Error fetching leave application data: ", error);
      });

    // Get employee data
    getLookupItem("employee")
      .then((data: any) => {
        // Get position data
        getLookupItem("position")
          .then((position_data: any) => {
            setPositionData(position_data);
            let full_data = data?.map((data: any) => {
              const user_position = position_data?.find(
                (item: any) => item.position_code === data.position
              );

              return {
                ...data,
                position: user_position?.position_name,
                key: data._id,
              };
            });
            let key = "staff_id_num";
            full_data = full_data
              .map((element: any) => {
                return {
                  ...element,
                };
              })
              .sort((a: any, b: any) => a[key].localeCompare(b[key]));
            setEmployeeData(full_data);
          })
          .catch((error: any) => {
            console.error("Error fetching position data: ", error);
          });
      })
      .catch((error: any) => {
        console.error("Error fetching employee data: ", error);
      });
  }, []);

  useEffect(() => {
    // console.log("searchRecord activated: ", searchRecord);
    if (searchRecord?.length > 0) {
      // setTimeout(() => {
      setSelectedRowKeys([]);
      setSelectedUsers([]);
      // }, 500)

      //console.log("Search record testing", searchRecord);
      let data: any = [...employeeData];
      // if (tabKey == "employee") {
      //     data = [...dataTable];
      // }
      let filteredData = data
        .map((element: any) => {
          let bool = searchRecord?.every((item: any) => {
            if (item.columnKey.includes("date")) {
              const dateObject = new Date(element[item.columnKey]);
              // console.log("im here", dateObject);
              const day = String(dateObject.getDate()).padStart(2, "0"); // Get day with leading zero
              const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Get month with leading zero
              const year = String(dateObject.getFullYear()); // Get full year

              // Check if any part of the date contains "04"
              return (
                day.includes(item.value) ||
                month.includes(item.value) ||
                year.includes(item.value) ||
                moment(dateObject)
                  .format("DD MMM YYYY")
                  .toLowerCase()
                  .includes(item.value.toLowerCase())
              );
            } else {
              return element[item.columnKey]
                ?.toString()
                ?.toLowerCase()
                .includes(item.value.toLowerCase());
            }
          });
          if (bool) return element;
        })
        .filter((element: any) => element !== null && element !== undefined);
      setFilteredDataTable(filteredData);
    } else {
      setSelectedRowKeys([]);
      setSelectedUsers([]);
      setFilteredDataTable(null);
    }
  }, [searchRecord]);

  // Triggers the useState or modal to pop-up when 'Generate Monthly Report' button is being clicked
  const handleGenerateMonthlyReportClick = () => {
    setMonthlyReportModalVisible(true);
  };

  // Triggers the useState or modal to close when 'Cancel' button is being clicked
  const handleGenerateMonthlyReportModalCancel = () => {
    setMonthlyReportModalVisible(false);
    setMonthlyReportMonth(getCurrentMonth());
    setMonthlyReportYear(getCurrentYear());
  };

  // Function to filter the leave application data based on selected year and month
  const handleGenerateMonthlyReportModalCreate = () => {
    const selected_year = Number(monthlyreportyear);
    const selected_month = Number(monthlyreportmonth);
    //console.log("Selected Year: ", selected_year);
    //console.log("Selected Month: ", selected_month);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthIndex = Number(monthlyreportmonth) - 1; // Subtract 1 because array indices start from 0
    const monthName = monthNames[monthIndex];

    if (leaveData) {
      // Filter data by "approved" status
      const filter_approved_leave_status = leaveData.filter(
        (record: any) => record.status === "approved"
      );

      // Filter data by start and end dates
      const filter_dates = filter_approved_leave_status.filter((entry: any) => {
        const startDate = new Date(entry.start_date);
        const endDate = new Date(entry.end_date);

        const startMonth = startDate.getMonth() + 1; // JavaScript months are zero-based
        const endMonth = endDate.getMonth() + 1;

        return (
          (startMonth === selected_month &&
            startDate.getFullYear() === selected_year) ||
          (endMonth === selected_month &&
            startDate.getFullYear() === selected_year)
        );
      });

      // Sort filtered data by start and end dates
      const sort_dates = filter_dates.sort((a: any, b: any) => {
        // Convert start_date and end_date strings to Date objects
        const startDateA = new Date(a.start_date);
        const startDateB = new Date(b.start_date);
        const endDateA = new Date(a.end_date);
        const endDateB = new Date(b.end_date);

        // If start dates are equal, sort by end dates
        if (startDateA.getTime() === startDateB.getTime()) {
          return endDateA.getTime() - endDateB.getTime();
        }
        // Otherwise, sort by start dates
        return startDateA.getTime() - startDateB.getTime();
      });

      // Create buffer to hold the data based on its leave type
      const leave_list = sort_dates.reduce((acc: any, record: any) => {
        acc[record.leave_type] = acc[record.leave_type] || [];
        acc[record.leave_type].push(record);
        return acc;
      }, {});

      // Triggers the function to export the pdf by passing the data in this function/block-scope
      exportMonthlyReportToPdf(leave_list, monthName, selected_year);

      // Clear the buffer
      setMonthlyReportModalVisible(false);
      setMonthlyReportMonth(getCurrentMonth());
      setMonthlyReportYear(getCurrentYear());
      monthlyreportform.resetFields();
      monthlyreportform.setFieldValue("year", getCurrentYear());
      monthlyreportform.setFieldValue("month", getCurrentMonth());
    } else {
      Emitter.emit("alert", {
        type: "error",
        message: "There are no leaves!",
        description: "",
        top: true,
        closeable: false,
        timeout: 3000,
      });
    }
  };

  // Function to generate the monthly leave application report to pdf based on the obtained data
  const exportMonthlyReportToPdf = (
    leave_list: any,
    selected_month: any,
    selected_year: any
  ) => {
    setMonthlyReportModalVisible(false);
    monthlyreportform.setFieldValue("year", getCurrentYear());
    monthlyreportform.setFieldValue("month", getCurrentMonth());
    setMonthlyReportMonth(getCurrentMonth());
    setMonthlyReportYear(getCurrentYear());

    // Create instances to null
    let staff_name = "";
    let staff_id = "";
    let staff_position = "";

    // Generate the PDF report content
    const doc = new jsPDF("p", "mm", "a4");
    doc.addImage(RajangLogo, "png", 149, 10, 48, 11);
    doc.setFontSize(15);
    doc.setFont("Helvetica", "bold");
    doc.text("Monthly Leave Application Report", 14, 26);

    // Print selected month and year
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text("Year", 14, 35);
    doc.text("Month", 14, 40);
    doc.text(":", 30, 35);
    doc.text(":", 30, 40);
    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.text(`${selected_year}`, 33, 35);
    doc.text(`${selected_month}`, 33, 40);

    // Calculate position for rendering
    let yOffset = 52; // Initial vertical offset
    // Function to add a table to the PDF
    const addTable = (dataSource: any, columns: any, header: any) => {
      const checkPageOverflow = (offset: any) => {
        const pageHeight = doc.internal.pageSize.height;
        if (offset + 20 > pageHeight) {
          // Adjust threshold as needed
          doc.addPage();
          offset = 20; // Reset offset for the new page
        }
        return offset;
      };
      yOffset = checkPageOverflow(yOffset);
      doc.setFontSize(13);
      doc.setFont("Helvetica", "bold");
      doc.text(header, 14, yOffset);
      yOffset += 2; // Increase vertical offset for spacing
      yOffset = checkPageOverflow(yOffset);
      autoTable(doc, {
        startY: yOffset,
        head: [columns.map((col: any) => col.title)],
        body: dataSource.map((data: any) =>
          columns.map((col: any) => {
            return data[col.dataIndex];
          })
        ),
        styles: { fontSize: 8 },
        theme: "grid",
        headStyles: {
          fillColor: [0, 0, 0],
          textColor: [255, 255, 255],
          halign: "center",
        },
        bodyStyles: { halign: "center" },
      });
      yOffset = (doc as any).autoTable.previous.finalY + 18; // Update vertical offset
      yOffset = checkPageOverflow(yOffset);
    };

    // Function to print timestamp
    const addFooter = () => {
      var pageCount = (doc as any).internal.getNumberOfPages(); // Get total number of pages
      for (var i = 1; i <= pageCount; i++) {
        // Iterate through each page
        doc.setPage(i); // Set current page

        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        doc.text(`${i}/${pageCount}`, 198, doc.internal.pageSize.height - 8); // Add page numbering

        doc.setFontSize(10);
        doc.setFont("Helvetica", "bolditalic");
        doc.text(
          `Generated on: ${currentDateTime}`,
          120,
          doc.internal.pageSize.height - 3
        ); // Add footer
      }
    };

    // Set and define the column for the table for the report
    const monthly_report_columns = [
      {
        title: "No.",
        dataIndex: "index",
        key: "index",
      },
      {
        title: "Staff ID",
        dataIndex: "staff_id",
        key: "staff_id",
      },
      {
        title: "Staff Name",
        dataIndex: "staff_name",
        key: "staff_name",
      },
      {
        title: "Position",
        dataIndex: "staff_position",
        key: "staff_position",
      },
      {
        title: "Leave Date(s)",
        dataIndex: "date_range",
        key: "date_range",
      },
      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
      },
    ];

    // Loop through leave_list
    if (Object.keys(leave_list).length !== 0) {
      Object.keys(leave_list)
        .sort()
        ?.forEach((leaveType: any) => {
          let buffer_list: any = [];
          const records = leave_list[leaveType];
          records.map((entry: any) => {
            employeeData.filter((record: any) => {
              if (record._id === entry.employee_id.toString()) {
                staff_name = record.fullname_nric;
                staff_id = record.staff_id_num;
                const position = record.position ? record.position : "-";
                staff_position = position;
                return true;
              }
              return false;
            });
            const startDate = moment(entry.start_date).format("DD MMM YYYY");
            const endDate = moment(entry.end_date).format("DD MMM YYYY");
            const date_range = `${startDate} - ${endDate}`;
            let duration: any = daysCalculation(
              workdays,
              holidays,
              entry.start_date,
              entry.end_date,
              entry.half_days
            );

            // let duration: any =
            //   moment(entry.end_date).diff(moment(entry.start_date), "days") + 1; // Add 1 to include both start and end dates
            duration = duration <= 1 ? `${duration} day` : `${duration} days`;
            buffer_list.push({
              staff_name,
              staff_id,
              staff_position,
              date_range,
              duration,
            });
            staff_name = "";
            staff_id = "";
            staff_position = "";
          });

          // To give numbering to the list
          const DataSource = buffer_list.map((leave: any, index: any) => ({
            ...leave,
            index: index + 1,
            key: leave._id,
          }));

          const leaveReportColumns = monthly_report_columns;

          // Check the existance of the list data -- will not show the table if there is no data
          if (DataSource.length > 0) {
            addTable(
              DataSource,
              leaveReportColumns,
              `Leave Type: ${capitalizeFirstLetter(leaveType)}`
            );
          }
        });
    } else {
      doc.text("Leaves were not taken by any employees.", 15, yOffset + 5);
    }

    // Call addFooter() function after content generation
    addFooter();

    // Naming format for pdf document
    const pdfName = `RDS MONTHLY LEAVE APPLICATION REPORT (${selected_month.toUpperCase()} ${selected_year}).pdf`; // Set the desired PDF name here
    doc.save(pdfName);
    message.success(
      "The report has been successfully generated and downloaded!",
      2
    );
  };
  // const exportMonthlyReportToPdf = (
  //     // leavetypeData: any,
  //     selected_month: any,
  //     selected_year: any
  // ) => {
  //     setMonthlyReportModalVisible(false);
  //     monthlyreportform.setFieldValue("year", getCurrentYear());
  //     monthlyreportform.setFieldValue("month", getCurrentMonth());
  //     setMonthlyReportMonth(getCurrentMonth());
  //     setMonthlyReportYear(getCurrentYear());

  //     // Generate the PDF report content
  //     const doc = new jsPDF("p", "mm", "a4");
  //     doc.addImage(RajangLogo, "png", 149, 10, 48, 11);
  //     doc.setFontSize(15);
  //     doc.setFont("Helvetica", "bold");
  //     doc.text("Monthly Leave Application Report", 14, 26);

  //     // Function to print timestamp
  //     const addFooter = () => {
  //         var pageCount = (doc as any).internal.getNumberOfPages(); // Get total number of pages
  //         for (var i = 1; i <= pageCount; i++) {
  //             // Iterate through each page
  //             doc.setPage(i); // Set current page

  //             doc.setFontSize(12);
  //             doc.setFont("Helvetica", "normal");
  //             doc.text(
  //                 `${i}/${pageCount}`,
  //                 198,
  //                 doc.internal.pageSize.height - 8
  //             ); // Add page numbering

  //             doc.setFontSize(10);
  //             doc.setFont("Helvetica", "bolditalic");
  //             doc.text(
  //                 `Generated on: ${currentDateTime}`,
  //                 120,
  //                 doc.internal.pageSize.height - 3
  //             ); // Add footer
  //         }
  //     };

  //     // Print selected month and year
  //     doc.setFontSize(12);
  //     doc.setFont("Helvetica", "bold");
  //     doc.text("Year", 14, 35);
  //     doc.text("Month", 14, 40);
  //     doc.text(":", 30, 35);
  //     doc.text(":", 30, 40);
  //     doc.setFontSize(12);
  //     doc.setFont("Helvetica", "normal");
  //     doc.text(`${selected_year}`, 33, 35);
  //     doc.text(`${selected_month}`, 33, 40);

  //     // Calculate position for rendering
  //     let yOffset = 52; // Initial vertical offset

  //     // Set and define the column for the table for the report
  //     const monthly_report_columns = [
  //         {
  //             title: "No.",
  //             dataIndex: "index",
  //             key: "index",
  //         },
  //         {
  //             title: "Staff ID",
  //             dataIndex: "staff_id",
  //             key: "staff_id",
  //         },
  //         {
  //             title: "Staff Name",
  //             dataIndex: "staff_name",
  //             key: "staff_name",
  //         },
  //         {
  //             title: "Position",
  //             dataIndex: "staff_position",
  //             key: "staff_position",
  //         },
  //         {
  //             title: "Leave Date(s)",
  //             dataIndex: "date_range",
  //             key: "date_range",
  //         },
  //         {
  //             title: "Duration",
  //             dataIndex: "duration",
  //             key: "duration",
  //         },
  //     ];

  //     // Function to add a table to the PDF
  //     const addTable = (dataSource: any, columns: any, header: any) => {
  //         doc.setFontSize(13);
  //         doc.setFont("Helvetica", "bold");
  //         doc.text(header, 14, yOffset);
  //         yOffset += 2; // Increase vertical offset for spacing
  //         autoTable(doc, {
  //             startY: yOffset,
  //             head: [columns.map((col: any) => col.title)],
  //             body: dataSource.map((data: any) =>
  //                 columns.map((col: any) => {
  //                     return data[col.dataIndex];
  //                 })
  //             ),
  //             styles: { fontSize: 11 },
  //             theme: "grid",
  //             headStyles: {
  //                 fillColor: [0, 0, 0],
  //                 textColor: [255, 255, 255],
  //                 halign: "center",
  //             },
  //             bodyStyles: { halign: "center" },
  //         });
  //         yOffset = (doc as any).autoTable.previous.finalY + 18; // Update vertical offset
  //     };

  //     // Loop through each leave type in leavetypeData
  //     leavetypeData.forEach((leaveType: any) => {
  //         const buffer_list: any = [];
  //         // Filter leave data by leave type
  //         const leave_list = leaveData.filter(
  //             (record: any) =>
  //                 record.leave_type_code === leaveType.leave_type_code &&
  //                 record.status === "approved"
  //         );

  //         // Populate buffer_list with formatted data
  //         leave_list.forEach((entry: any) => {
  //             const staff = employeeData.find(
  //                 (record: any) => record._id === entry.employee_id.toString()
  //             );
  //             const staff_name = staff ? staff.fullname_nric : "";
  //             const staff_id = staff ? staff.staff_id_num : "";
  //             const staff_position = staff
  //                 ? staff.position
  //                     ? staff.position
  //                     : "-"
  //                 : "";
  //             const startDate = moment(entry.start_date).format("DD MMM YYYY");
  //             const endDate = moment(entry.end_date).format("DD MMM YYYY");
  //             const date_range = `${startDate} - ${endDate}`;
  //             let duration: any = daysCalculation(
  //                 workdays,
  //                 holidays,
  //                 entry.start_date,
  //                 entry.end_date,
  //                 entry.half_days
  //             );
  //             duration =
  //                 duration <= 1 ? `${duration} day` : `${duration} days`;
  //             buffer_list.push({
  //                 staff_name,
  //                 staff_id,
  //                 staff_position,
  //                 date_range,
  //                 duration,
  //             });
  //         });

  //         // Check if there's data for this leave type
  //         if (buffer_list.length > 0) {
  //             // Add table for this leave type
  //             addTable(
  //                 buffer_list.map((leave: any, index: any) => ({
  //                     ...leave,
  //                     index: index + 1,
  //                 })),
  //                 monthly_report_columns,
  //                 `Leave Type: ${leaveType.leave_type_name}`
  //             );
  //         } else {
  //             doc.text(
  //                 `No ${leaveType.leave_type_name} Leaves`,
  //                 15,
  //                 yOffset + 5
  //             );
  //         }
  //     });

  //     // Call addFooter() function after content generation
  //     addFooter();

  //     // Naming format for pdf document
  //     const pdfName = `RDS MONTHLY LEAVE APPLICATION REPORT (${selected_month.toUpperCase()} ${selected_year}).pdf`; // Set the desired PDF name here
  //     doc.save(pdfName);
  //     message.success(
  //         "The report has been successfully generated and downloaded!",
  //         2
  //     );
  // };

  // Triggers the useState or modal to pop-up when 'Generate Annual Report(s)' button is being clicked
  const handleGenerateAnnualReportsClick = (record: any) => {
    // setSelectedEmployee(employeeData);
    setAnnualReportsModalVisible(true);
  };

  // Triggers the useState or modal to close when 'Cancel' button is being clicked
  const handleGenerateAnnualReportsModalCancel = () => {
    setAnnualReportsModalVisible(false);
    setAnnualReportYear(getCurrentYear());
  };

  // Function to filter the leave application data based on employee based on the selected year, leave type and application status
  const handleGenerateAnnualReportsModalCreate = () => {
    // Loop through each selected user
    if (leaveData) {
      if (selectedUsers.length > 0) {
        selectedUsers.forEach((selectedUser) => {
          handleGenerateAnnualReportModalCreate(selectedUser);
        });
        message.success(
          "The report(s) have been successfully generated and downloaded!",
          2
        );
      } else {
        message.error("Please select at least one employee!", 2);
      }
    } else {
      Emitter.emit("alert", {
        type: "error",
        message: "There are no leaves!",
        description: "",
        top: true,
        closeable: false,
        timeout: 3000,
      });
    }
  };

  // Function to filter the leave application data based on employee based on the selected year, leave type and application status
  const handleGenerateAnnualReportModalCreate = (
    selectedvarEmployee: any = selectedEmployee
  ) => {
    const filter_employee_id = leaveData.filter((record: any) => {
      return record.employee_id === selectedvarEmployee._id;
    });
    // Filter the data based on the selected year
    const filter_year = filter_employee_id.filter((record: any) => {
      // Extract year from start date and end date
      const [startYear, endYear] = [
        new Date(record.start_date).getFullYear(),
        new Date(record.end_date).getFullYear(),
      ];

      // Ensure year is a number
      const filterYear = Number(annualreportyear);

      // Check if the record meets the filter conditions
      return startYear === filterYear && endYear === filterYear;
    });

    // Filter the data "approved" leave type
    const filter_approved_leave_status = filter_year.filter(
      (record: any) => record.status === "approved"
    );

    // Sort filtered data by start and end dates
    filter_approved_leave_status.sort((a: any, b: any) => {
      // Convert start_date and end_date strings to Date objects
      const startDateA = new Date(a.start_date);
      const startDateB = new Date(b.start_date);
      const endDateA = new Date(a.end_date);
      const endDateB = new Date(b.end_date);

      // If start dates are equal, sort by end dates
      if (startDateA.getTime() === startDateB.getTime()) {
        return endDateA.getTime() - endDateB.getTime();
      }
      // Otherwise, sort by start dates
      return startDateA.getTime() - startDateB.getTime();
    });

    // Create buffer to hold the data based on its leave type
    const leave_list = filter_approved_leave_status.reduce(
      (acc: any, record: any) => {
        acc[record.leave_type] = acc[record.leave_type] || [];
        acc[record.leave_type].push(record);
        return acc;
      },
      {}
    );

    // Generate the PDF report
    exportAnnualReportToPdf(leave_list, selectedvarEmployee);

    // Clear the temporary lists
    setAnnualReportModalVisible(false);
    setAnnualReportYear(getCurrentYear());
    annualreportform.resetFields();
    annualreportform.setFieldValue("year", getCurrentYear());
  };

  // Function to generate the monthly leave application report to pdf based on the obtained data
  const exportAnnualReportToPdf = (leave_list: any, selectedEmployee: any) => {
    setAnnualReportModalVisible(false);
    setAnnualReportYear(getCurrentYear());
    annualreportform.setFieldValue("year", getCurrentYear());

    const doc = new jsPDF("p", "mm", "a4");
    doc.addImage(RajangLogo, "png", 149, 10, 48, 11);
    doc.setFontSize(15);
    doc.setFont("Helvetica", "bold");
    doc.text(`${annualreportyear} Annual Leave Application Report`, 14, 26);

    const position = selectedEmployee.position
      ? selectedEmployee.position
      : "-";
    // Print employee details
    doc.setFontSize(12);
    doc.setFont("Helvetica", "bold");
    doc.text("Staf ID", 14, 35);
    doc.text("Staf Name", 14, 40);
    doc.text("Position", 14, 45);
    doc.text(":", 37, 35);
    doc.text(":", 37, 40);
    doc.text(":", 37, 45);
    doc.setFont("Helvetica", "normal");
    doc.text(`${selectedEmployee.staff_id_num}`, 40, 35);
    doc.text(`${selectedEmployee.fullname_nric}`, 40, 40);
    doc.text(`${position}`, 40, 45);

    // Calculate position for rendering
    let yOffset = 60; // Initial vertical offset
    // Function to add a table to the PDF
    const addTable = (
      dataSource: any,
      columns: any,
      header: any,
      selectedEmployeeData: any,
      leave_type: any
    ) => {
      // Check and handle page overflow before adding new content
      const checkPageOverflow = (offset: any) => {
        const pageHeight = doc.internal.pageSize.height;
        if (offset + 20 > pageHeight) {
          // Adjust threshold as needed
          doc.addPage();
          offset = 20; // Reset offset for the new page
        }
        return offset;
      };
      yOffset = checkPageOverflow(yOffset);
      doc.setFontSize(13);
      doc.setFont("Helvetica", "bold");
      doc.text(header, 14, yOffset);
      yOffset += 2; // Increase vertical offset for spacing
      yOffset = checkPageOverflow(yOffset);
      autoTable(doc, {
        startY: yOffset,
        head: [columns.map((col: any) => col.title)],
        body: dataSource.map((data: any) =>
          columns.map((col: any) => {
            return data[col.dataIndex];
          })
        ),
        styles: { fontSize: 8 },
        theme: "grid",
        headStyles: {
          fillColor: [0, 0, 0],
          textColor: [255, 255, 255],
          halign: "center",
        },
        bodyStyles: { halign: "center" },
      });
      yOffset = (doc as any).autoTable.previous.finalY;
      yOffset = checkPageOverflow(yOffset);
      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Leave Entitlement", 15, yOffset + 5);
      doc.text("Used Leave", 15, yOffset + 10);
      doc.text("Leave Balance", 15, yOffset + 15);
      doc.text(":", 47, yOffset + 5);
      doc.text(":", 47, yOffset + 10);
      doc.text(":", 47, yOffset + 15);
      doc.setFontSize(10);
      doc.setFont("Helvetica", "normal");
      //console.log(selectedEmployeeData);
      let entitled = 0;
      let used = 0;
      for (const item of selectedEmployeeData?.leave_entitled) {
        if (item.leave_type === leave_type) {
          entitled = item.entitled;
          used = item.used;
        }
      }
      // const entitled =
      //   selectedEmployeeData?.leave_entitled?.[leave_type]?.entitled;
      // const used = selectedEmployeeData?.leave_entitled?.[leave_type]?.used;
      doc.text(`${entitled} day(s)`, 50, yOffset + 5);
      doc.text(`${used} day(s)`, 50, yOffset + 10);
      const balance = Number(entitled) - Number(used);
      doc.text(`${balance} day(s)`, 50, yOffset + 15);
      yOffset = (doc as any).autoTable.previous.finalY + 30; // Update vertical offset
    };

    // Function to print timestamp
    const addFooter = () => {
      var pageCount = (doc as any).internal.getNumberOfPages(); // Get total number of pages
      for (var i = 1; i <= pageCount; i++) {
        // Iterate through each page
        doc.setPage(i); // Set current page

        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        doc.text(`${i}/${pageCount}`, 198, doc.internal.pageSize.height - 8); // Add page numbering

        doc.setFontSize(10);
        doc.setFont("Helvetica", "bolditalic");
        doc.text(
          `Generated on: ${currentDateTime}`,
          120,
          doc.internal.pageSize.height - 3
        ); // Add footer
      }
    };

    // Set and define the column for the table for the report
    const annual_report_columns = [
      {
        title: "No.",
        dataIndex: "index",
        key: "index",
      },
      {
        title: "Leave Date(s)",
        dataIndex: "date_range",
        key: "date_range",
      },
      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
        render: (item: any) => {
          return `${item} days`;
        },
      },
    ];

    //  Loop through leave_list
    if (Object.keys(leave_list).length !== 0) {
      Object.keys(leave_list)
        .sort()
        ?.forEach((leaveType: any) => {
          let buffer_list: any = [];
          const records = leave_list[leaveType];
          records.map((entry: any) => {
            const startDate = moment(entry.start_date).format("DD MMM YYYY");
            const endDate = moment(entry.end_date).format("DD MMM YYYY");
            const date_range = `${startDate} - ${endDate}`;
            let duration: any = daysCalculation(
              workdays,
              holidays,
              entry.start_date,
              entry.end_date,
              entry.half_days
            );

            // let duration: any =
            //   moment(entry.end_date).diff(moment(entry.start_date), "days") + 1; // Add 1 to include both start and end dates
            duration = duration <= 1 ? `${duration} day` : `${duration} days`;
            buffer_list.push({
              date_range,
              duration,
            });
          });
          // To give numbering to the list
          const DataSource = buffer_list.map((leave: any, index: any) => ({
            ...leave,
            index: index + 1,
            key: leave._id,
          }));

          const leaveReportColumns = annual_report_columns;

          // Check the existance of the list data -- will not show the table if there is no data
          if (DataSource.length > 0) {
            addTable(
              DataSource,
              leaveReportColumns,
              `Leave Type: ${capitalizeFirstLetter(leaveType)}`,
              selectedEmployee,
              `${leaveType}`
            );
          }
        });
    } else {
      doc.text(
        `Leaves were not taken by ${selectedEmployee.fullname_nric}.`,
        15,
        yOffset + 5
      );
    }

    // Call addFooter() function after content generation
    addFooter();

    // Naming format for pdf document
    const pdfName = `${annualreportyear} RDS ANNUAL LEAVE APPLICATION REPORT (${selectedEmployee.fullname_nric.toUpperCase()}_${
      selectedEmployee.staff_id_num
    }).pdf`; // Set the desired PDF name here
    doc.save(pdfName);
    // message.success(
    //   "The report has been successfully generated and downloaded!",
    //   2
    // );
  };

  // Set and define the column to display the employee list
  const employee_columns = [
    {
      title: isMobile ? (
        "Employee ID"
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Employee ID"
            columnKey="staff_id_num"
            key="staff_id_num"
          />
        </div>
      ),
      dataIndex: "staff_id_num",
      key: "staff_id_num",
      // width: "30%",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["staff_id_num"]
          .toString()
          .localeCompare(b["staff_id_num"].toString());
      },
    },
    {
      title: isMobile ? (
        "Employee Name"
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Employee Name"
            columnKey="fullname_nric"
            key="fullname_nric"
          />
        </div>
      ),
      dataIndex: "fullname_nric",
      key: "fullname_nric",
      // width: "40%",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        return a["fullname_nric"]
          .toString()
          .localeCompare(b["fullname_nric"].toString());
      },
    },
    {
      title: isMobile ? (
        "Position"
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <SearchButton
            handleSearch={handleSearch}
            headerName="Position"
            columnKey="position"
            key="position"
          />
        </div>
      ),
      dataIndex: "position",
      key: "position",
      render: (text: any) => (text ? text : "-"),
      // width: "30%",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        const positionA =
          a["position"] === undefined ? "-" : a["position"].toString();
        const positionB =
          b["position"] === undefined ? "-" : b["position"].toString();

        return positionA.localeCompare(positionB);
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   dataIndex: "action",
    //   render: (text: any, record: any) => (
    //     <div>
    //       <Button
    //         type="dashed"
    //         onClick={() => handleGenerateAnnualReportClick(record)}
    //       >
    //         Generate Annual Report
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  // Get the current year
  const currentYear = new Date().getFullYear();

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedUsers(selectedRows);
    },
  };

  // Generate an array of 5 years from the current year (For Generate Annual Report function)
  const years = Array.from({ length: 5 }, (_, index) => currentYear - index);

  return (
    <div
      style={{
        overflow: "scroll",
      }}
    >
        <Space direction={isMobile ? "vertical" : "horizontal"}>
          <Button
            onClick={handleGenerateMonthlyReportClick}
            style={{ width: "200px" }}
          >
            Generate Monthly Report
          </Button>
          <Modal
            open={monthly_report_modal_visible}
            title={
              <div
                style={{
                  padding: "15px",
                  backgroundColor: "#343642",
                  color: "white",
                  minHeight: "25px",
                  maxHeight: "25px",
                }}
              >
                Monthly Leave Application Report
              </div>
            }
            okText="Generate"
            onCancel={handleGenerateMonthlyReportModalCancel}
            onOk={handleGenerateMonthlyReportModalCreate}
            closeIcon={<CustomCloseIcon />}
            className="modal-report"
            footer={[
              <div style={{ padding: "16px" }}>
                <Button
                  key="back"
                  onClick={handleGenerateMonthlyReportModalCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>

                <Button
                  key="submit"
                  type="primary"
                  onClick={handleGenerateMonthlyReportModalCreate}
                >
                  Generate
                </Button>
              </div>,
            ]}
          >
            <Form
              layout="vertical"
              form={monthlyreportform}
              style={{ marginLeft: "15px", marginRight: "10px" }}
            >
              <Form.Item label="Year" name="year">
                <Select
                  defaultValue={monthlyreportyear}
                  onChange={(value) => setMonthlyReportYear(value)}
                >
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <br></br>
              <Form.Item label="Month" name="month">
                <Select
                  defaultValue={monthlyreportmonth}
                  onChange={(value: any) => {
                    // console.log("Test", value);
                    setMonthlyReportMonth(value);
                  }}
                >
                  <Option value="1">January</Option>
                  <Option value="2">February</Option>
                  <Option value="3">March</Option>
                  <Option value="4">April</Option>
                  <Option value="5">May</Option>
                  <Option value="6">June</Option>
                  <Option value="7">July</Option>
                  <Option value="8">August</Option>
                  <Option value="9">September</Option>
                  <Option value="10">October</Option>
                  <Option value="11">November</Option>
                  <Option value="12">December</Option>
                </Select>
              </Form.Item>
            </Form>
            <p
              style={{
                opacity: 0.5,
                fontSize: "13px",
                marginLeft: "15px",
                marginRight: "10px",
              }}
            >
              Please note that monthly leave application report will include
              leaves applied by <b>ALL EMPLOYEES</b> in the specified month.
            </p>
          </Modal>

          <Button
            onClick={handleGenerateAnnualReportsClick}
            style={{ width: "200px" }}
          >
            Generate Annual Report(s)
          </Button>

          <Modal
            open={annual_reports_modal_visible}
            title={
              <div
                style={{
                  padding: "15px",
                  backgroundColor: "#343642",
                  color: "white",
                  minHeight: "25px",
                  maxHeight: "25px",
                }}
              >
                Annual Leave Application Report(s)
              </div>
            }
            okText="Generate"
            onCancel={handleGenerateAnnualReportsModalCancel}
            onOk={handleGenerateAnnualReportsModalCreate}
            closeIcon={<CustomCloseIcon />}
            className="modal-report"
            footer={[
              <div style={{ padding: "16px" }}>
                <Button
                  key="back"
                  onClick={handleGenerateAnnualReportsModalCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>

                <Button
                  key="submit"
                  type="primary"
                  onClick={handleGenerateAnnualReportsModalCreate}
                >
                  Generate
                </Button>
              </div>,
            ]}
          >
            <p
              style={{
                display: "inline",
                marginRight: "10px",
                marginLeft: "15px",
              }}
            >
              Number of Selected Employees :{" "}
            </p>
            <p style={{ display: "inline" }}>
              {selectedUsers.length}
              <br></br>
            </p>
            <Form
              layout="vertical"
              form={annualreportform}
              style={{
                marginLeft: "15px",
                marginRight: "10px",
                marginTop: "10px",
              }}
            >
              <Form.Item label="Year" name="year">
                <Select
                  defaultValue={annualreportyear}
                  onChange={(value) => setAnnualReportYear(value)}
                >
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <br></br>
            </Form>
          </Modal>

          <Text>Employees: </Text>
          {selectedUsers.length >= 0 && (
            <Text>Selected {selectedUsers.length} employees</Text>
          )}
        </Space>
      <Table
        columns={employee_columns}
        dataSource={filteredDataTable || employeeData}
        style={{ marginTop: "30px" }}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [20, 50, 100],
          showSizeChanger: true,
        }}
      />
      {/* <Modal
        open={annual_report_modal_visible}
        title={
          <div
            style={{
              padding: "15px",
              backgroundColor: "#343642",
              color: "white",
              minHeight: "25px",
              maxHeight: "25px",
            }}
          >
            Annual Leave Application Report
          </div>
        }
        okText="Generate"
        onCancel={handleGenerateAnnualReportModalCancel}
        onOk={handleGenerateAnnualReportModalCreate}
        closeIcon={<CustomCloseIcon />}
        className="modal-report"
        footer={[
          <div style={{ padding: "16px" }}>
            <Button
              key="back"
              onClick={handleGenerateAnnualReportModalCancel}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>

            <Button
              key="submit"
              type="primary"
              onClick={handleGenerateAnnualReportModalCreate}
            >
              Generate
            </Button>
          </div>,
        ]}
      >
        <p style={{ display: "inline", marginRight: "10px" }}>
          Employee Name:{" "}
        </p>
        <p style={{ display: "inline" }}>
          {selectedEmployee.fullname_nric}
          <br></br>
        </p>
        <p style={{ display: "inline", marginRight: "35px" }}>Employee ID: </p>
        <p style={{ display: "inline" }}>
          {selectedEmployee.staff_id_num}
          <br></br>
        </p>
        <p style={{ display: "inline", marginRight: "30px" }}>Position: </p>
        <p style={{ display: "inline" }}>
          {selectedEmployee.position}
          <br></br>
          <br></br>
        </p>
        <Form layout="vertical">
          <Form.Item label="Year">
            <Select
              defaultValue={annualreportyear}
              onChange={(value) => setAnnualReportYear(value)}
            >
              {years.map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <br></br>
          {/* <Form.Item label="Leave Type">
					<Checkbox.Group value={leaveType} onChange={handleLeaveTypeChange}>
						<Checkbox value="annual">Annual</Checkbox>
						<Checkbox value="sick">Sick</Checkbox>
					</Checkbox.Group>
				</Form.Item>
				<br></br>
				<Form.Item label="Application Status">
					<Checkbox.Group value={applicationStatus} onChange={handleApplicationStatusChange}>
						<Checkbox value="approved">Approved</Checkbox>
						<Checkbox value="pending">Pending</Checkbox>
						<Checkbox value="rejected">Rejected</Checkbox>
					</Checkbox.Group>
				</Form.Item>
       </Form>
      </Modal> */}
    </div>
  );
};

export default LeaveReport;
