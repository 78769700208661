import { useContext, useEffect, useState } from "react";
import { Button, Table, Tabs, Modal, Form, Input } from "antd";

import type { ColumnsType } from "antd/es/table";
import {
  getAddButtonProp,
  getColumnList,
  getLookupItem,
  getFormOptionList,
} from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { HomeContext } from "./Home";
import { useNavigate } from "react-router";
import { updateLeaveItem } from "../services/api-server/leave";
import { updateEmployeeItem } from "../services/api-server/employee";
import { daysCalculation } from "../utils/utils";
// import { LeaveConfiguration } from "./LeaveConfiguration";

import LeaveApprovalForm from "../components/LeaveApprovalForm";
import LeaveApplication from "./LeaveApplication";
import moment from "moment";
import { sendEmail } from "../services/api-server/usertoken";
import { socket } from "../utils/socket";
import { isMobile } from "react-device-detect";

const UserApplication = (props: any) => {
  const context: any = useContext(HomeContext);
  const navigate: any = useNavigate();

  const role = props?.role;
  const userData = props?.userData;

  const [tabKey, setTabKey] = useState<any>(props.tabKey);
  const [subTabKey, setSubTabKey] = useState<any>(props.subTabKey || null);
  const containerTitle = props?.containerTitle;
  const holidayData = props?.holidayData;
  const workdayData = props?.workdayData;

  const [useraccData, setUseraccData] = useState<any>([]);

  const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dbData, setDbData] = useState<any>({});

  const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
  const [sideContainerOpen, setSideContainerOpen] = useState<any>(
    props.sideContainerOpen || false
  );
  const [activeRowKey, setActiveRowKey] = useState<any>(
    props.activeRowKey || null
  );
  const [activeRecord, setActiveRecord] = useState<any>(
    props.activeRecord || null
  );
  const [expandedRowKeys, setExpandRowKey] = useState<any>([]);

  const [addButtonProp, setAddButtonProp] = useState<any>(null);
  const [searchRecord, setSearchRecord] = useState<any>([]);
  const [tempRecord, setTempRecord] = useState<any>(null);

  const [tableHeight, setTableHeight] = useState<any>(null);
  const [ready, setReady] = useState<any>(false);

  const [activeGroupKey, setActiveGroupKey] = useState<any>(null);

  const [showRejectionModal, setShowRejectionModal] = useState<any>(false);
  const [rejectionModalStatus, setRejectionModalStatus] = useState<any>("");
  const [rejectionReason, setRejectionReason] = useState<any>("");

  const [showLeaveApplication, setShowLeaveApplication] = useState<any>(false);
  // const [formValues, setFormValues] = useState<any>({});
  // const [formReset, setFormReset] = useState<any>(false);
  const [leaveform] = Form.useForm();
  const [refreshData, setRefreshData] = useState<any>(false);

  const [openModal, setOpenModal] = useState<any>(true);

  // file upload uuid
  const [imgNameUUIDList, setImgNameUUIDList] = useState<any>([]);

  const currentDate = new Date();

  const url = process.env.REACT_APP_SOCKET_SERVER as string;

  // Get the admins' email
  useEffect(() => {
    getLookupItem("useracc").then((useraccData: any) => {
      const adminData = useraccData
        .filter((data: any) => data.roles[0] === "admin")
        .map((filteredData: any) => {
          return filteredData.email;
        });
      setUseraccData(adminData);
    });
  }, []);

  useEffect(() => {
    // console.log("tempRecord activated: ", tempRecord);
    if (searchRecord && tempRecord) {
      // console.log("11111", searchRecord, tempRecord);
      let recordIndex = searchRecord.findIndex((item: any) => {
        return item.columnKey === tempRecord.columnKey;
      });
      if (recordIndex !== -1) {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record[recordIndex] = tempRecord;
          setSearchRecord(_record);
        } else {
          let _record = [...searchRecord];
          _record.splice(recordIndex, 1);
          setSearchRecord(_record);
        }
      } else {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record.push(tempRecord);
          setSearchRecord(_record);
        }
      }
    }
  }, [tempRecord]);

  useEffect(() => {
    refresh_employee();
    // console.log("searchRecord activated: ", searchRecord);
    if (searchRecord?.length > 0) {
      let data: any = [...dataTable];
      // if (tabKey == "employee") {
      //     data = [...dataTable];
      // }

      let filteredData = data
        .map((element: any) => {
          let bool = searchRecord.every((item: any) => {
            if (item.columnKey === "leave_date") {
              const filteredDates = element["date_range"]?.filter(
                (date: any) => {
                  const dateObject = new Date(date);
                  const day = String(dateObject.getDate()).padStart(2, "0"); // Get day with leading zero
                  const month = String(dateObject.getMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Get month with leading zero
                  const year = String(dateObject.getFullYear()); // Get full year
                  // console.log(
                  //     "test user",
                  //     day,
                  //     month,
                  //     year,
                  //     item.value
                  // );
                  // Check if any part of the date contains
                  return (
                    day.includes(item.value) ||
                    month.includes(item.value) ||
                    year.includes(item.value) ||
                    moment(dateObject)
                      .format("DD MMM YYYY")
                      .toLowerCase()
                      .includes(item.value.toLowerCase())
                  );
                }
              );
              return filteredDates.length > 0; // Return true if there are dates containing the specified value
            } else {
              return element[item.columnKey]
                ?.toString()
                ?.toLowerCase()
                .includes(item.value.toLowerCase());
            }
          });
          if (bool) return element;
        })
        .filter((element: any) => element !== null && element !== undefined);
      setFilteredDataTable(filteredData);
    } else {
      setFilteredDataTable(null);
    }
  }, [searchRecord, refreshData]);

  const handleSearch = (record: any) => {
    setTempRecord(record);
  };

  const refresh_employee = () => {
    Promise.all([getLookupItem("employee")]).then((data: any) => {
      const object: any = {};
      object["employee_id"] = data[0];
      setDbData(object);
      // console.log("object", object);
    });
  };

  const handleTabChange = async (key: any) => {
    try {
      await context.handlePageChange();
      setTableColumns([]);
      setDataTable([]);
      setSideContainerOpen(false);
      setSubTabKey(key);
      setExpandRowKey([]);
      setActiveRowKey(null);
      setActiveGroupKey(null);
    } catch {}
  };

  const calculateTableHeight = () => {
    const table: any = document.getElementById("table-container");
    if (table) {
      setTableHeight(table?.clientHeight - 65 - 64);
    }
  };

  const RejectionModal = () => {
    const handleOk = () => {
      if (rejectionReason) {
        const initial_status = activeRecord.status;
        const values = activeRecord;
        values.status = rejectionModalStatus;
        values.closed_date = new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        values.denial_reason = rejectionReason;
        //console.log("Received values of form:", values);

        // const employee_db_id = userData._id;
        // const employee_data = userData;
        const employee_db_id = dbData["employee_id"].find(
          (item: any) => item._id == userData._id
        )?._id;
        const employee_data: any = dbData["employee_id"].find(
          (item: any) => item._id == userData._id
        );
        // employee_data.leave_entitled[values.leave_type].used -= daysCalculation(
        //     workdayData,
        //     holidayData,
        //     values.start_date,
        //     values.end_date,
        //     values.half_days
        // );
        const content = `Dear Admin,\n\n${employee_data.fullname_nric} has ${values.status} his/her leave due to the following reason:\n\n${values.denial_reason}.\n\nPlease review the leave application at ${url}\n\nThank you.\n\nBest regards,\nRajang Digital Solutions HR Team`;
        updateLeaveItem([activeRowKey], values)
          .then((_data: any) => {
            if (
              rejectionModalStatus === "cancelled"
              //   &&initial_status === "approved"
            ) {
              if (initial_status === "pending") {
                for (const item of employee_data.leave_entitled) {
                  if (item.leave_type === values.leave_type) {
                    if (item.entitled !== 0) {
                      item.pending -= daysCalculation(
                        workdayData,
                        holidayData,
                        values.start_date,
                        values.end_date,
                        values.half_days
                      );
                    }
                  }
                }
              } else if (initial_status === "approved") {
                for (const item of employee_data.leave_entitled) {
                  if (item.leave_type === values.leave_type) {
                    if (item.entitled !== 0) {
                      item.used -= daysCalculation(
                        workdayData,
                        holidayData,
                        values.start_date,
                        values.end_date,
                        values.half_days
                      );
                    }
                  }
                }
              }

              const requestedClaimAmount = values.requestedclaim; // Assuming `requested_claim` holds the amount the user wants to claim
              const currentClaimBalance = employee_data.claims; // Retrieve the current claim balance

              // Update the claim balance
              if (requestedClaimAmount > 0) {
                employee_data.claims =
                  currentClaimBalance + requestedClaimAmount;
              }
              // console.log("employee_data", employee_data);
              return updateEmployeeItem([employee_db_id], employee_data);
            }
          })
          .then((_data: any) => {
            Emitter.emit("alert", {
              type: "success",
              title: "Success",
              message:
                values.leave_id_num +
                " Leave application " +
                rejectionModalStatus +
                ". Sending email...",
              description: "",
              top: true,
              closeable: false,
              timeout: 3000,
            });
          })
          .catch((error: any) => {
            //console.log("error", error);
          })
          .finally(() => {
            setShowRejectionModal(false);
            setSideContainerOpen(false);
            setActiveRowKey(null);
            setActiveGroupKey(null);
            setRejectionReason("");
            sendEmail(values, content, useraccData)
              .then((response: any) => {
                // Handle successful email sending
                console.log("Email sent successfully:", response);
              })
              .catch((error: any) => {
                // Handle errors
                // console.error("Error sending email:", error);
              });
            socket.emit("pong-update", () => {
              // const duration = Date.now() - start;
              console.log("Pinging server");
            });
            // setExpandRowKey([]);
            // setDataTable([]);
            // setTableColumns([]);
            // setTabKey("leaveManager");
          });
      } else {
        // show an error message
        Emitter.emit("alert", {
          type: "error",
          title: "Error",
          message: "Please enter a reason for rejection.",
          description: "",
          top: true,
          closeable: false,
          timeout: 3000,
        });
      }
    };

    const handleCancel = () => {
      // Close the modal without performing any action
      setShowRejectionModal(false);
    };

    return (
      <Modal
        // title="Reason for Rejection"
        title={
          rejectionModalStatus == "rejected"
            ? "Reason for Rejection"
            : "Reason for Cancellation (This action can't be undone)"
        }
        open={showRejectionModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !rejectionReason }}
        className="rejection-modal"
      >
        <Input.TextArea
          placeholder={
            rejectionModalStatus == "rejected"
              ? "Enter reason for rejection..."
              : "Enter reason for cancellation..."
          }
          onChange={(e) => setRejectionReason(e.target.value)}
          value={rejectionReason}
        />
      </Modal>
    );
  };

  // const handleApproval = (values: any) => {
  //     values.status = "approved";
  //     values.denial_reason = "";
  //     values.closed_date = new Date().toISOString().slice(0, 19).replace("T", " ");
  //  //console.log("Received values of form:", values);

  //     const employee_db_id = dbData["employee_id"].find(
  //         (item: any) => item._id == values.employee_id
  //     )?._id;
  //     const employee_data = dbData["employee_id"].find(
  //         (item: any) => item._id == values.employee_id
  //     );

  //     const workingDays: any = daysCalculation(
  //         workdayData,
  //         holidayData,
  //         values.start_date,
  //         values.end_date
  //     );
  //     employee_data.leave_entitled[values.leave_type].used += workingDays;

  //     // daysCalculation(values.start_date, values.end_date).then((workingDays: any) => {
  //     //     employee_data.leave_entitled[values.leave_type].used += workingDays;
  //     // });
  //     // employee_data.leave_entitled[values.leave_type].used += values.days;

  //     updateLeaveItem([activeRowKey], values)
  //         .then((_data) => {
  //             // Success handler for updating leave item
  //             return updateEmployeeItem([employee_db_id], employee_data);
  //         })
  //         .then((_data) => {
  //             // Emit the event after both updates have been completed
  //             Emitter.emit("alert", {
  //                 type: "success",
  //                 title: "Success",
  //                 message: "Leave application " + values.leave_id_num + " approved.",
  //                 description: "",
  //                 top: true,
  //                 closeable: false,
  //                 timeout: 3000,
  //             });
  //         })
  //         .catch((error) => {
  //             // Error handler for any of the operations
  //          //console.log("Error:", error);
  //         })
  //         .finally(() => {
  //             // Additional cleanup or actions
  //             setSideContainerOpen(false);
  //             setActiveRowKey(null);
  //             setActiveGroupKey(null);
  //         });
  // };

  const setSideContainer = () => {
    if (sideContainerOpen) {
      return (
        // <div
        //   open={openModal}
        //   width={isMobile ? "100%" : "35%"}
        //   style={{ top: 20, borderRadius: "3px" }}
        //   closable={false}
        //   footer={null}
        // >
        <div
          className="lookup-side-container"
          style={{
            width: isMobile ? "100%" : "35%",
            maxHeight: "100%",
            // margin: "10px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRadius: "3px",
          }}
        >
          <div className="lookup-side-header" style={{ height: "50px" }}>
            <span
              style={{
                marginRight: "auto",
                minHeight: "32px",
                display: "flex",
                // overflow: "auto",
                alignItems: "center",
                color: "#ffffff",
              }}
            >
              {/* {tabKey.charAt(0).toUpperCase() +
                                tabKey.split("_").join(" ").slice(1)}{" "} */}
              Leave details
            </span>
            <button
              onClick={() => {
                setSideContainerOpen(false);
                setActiveRowKey(null);
                setActiveGroupKey(null);
              }}
              style={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "15px",
                // fontWeight: "600",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
          <div
            className="generic-content"
            style={{
              flex: "1",
              gap: "0px",
              maxHeight: "100%",
              overflow: "auto",
              flexWrap: "nowrap",
              backgroundColor: "#ffffff",
              border: "solid 1px",
              borderLeftColor: "#A7A7A7",
              borderRightColor: "#A7A7A7",
            }}
          >
            {sideContainerOpen && (
              <LeaveApprovalForm
                setTabKey={setTabKey}
                data={activeRecord}
                tabKey={tabKey}
                subTabKey={subTabKey}
                holidayData={holidayData}
                workdayData={workdayData}
              />
            )}
          </div>
          <div className="generic-footer" style={{ height: "50px" }}>
            {(activeRecord.status == "rejected" ||
              activeRecord.status == "cancelled" ||
              currentDate > new Date(activeRecord.start_date)) && (
              <div>
                <Button
                  onClick={() => {
                    setSideContainerOpen(false);
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Close
                </Button>
              </div>
            )}
            {(activeRecord.status == "approved" ||
              activeRecord.status == "pending") &&
              currentDate < new Date(activeRecord.start_date) && (
                <div>
                  <Button
                    onClick={() => {
                      setRejectionModalStatus("cancelled");
                      setShowRejectionModal(true);
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Cancel Leave
                  </Button>
                </div>
              )}
            {/* {activeRecord.status == "rejected" && (
                            <div>
                                <Button
                                    onClick={() => {
                                        setSideContainerOpen(false);
                                    }}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Close
                                </Button>
                            </div>
                        )} */}
          </div>
        </div>
      );
    }
  };

  // useEffect(() => {
  //     // Fetch employee data using getLookupItem function
  //     if (role != "admin") {
  //       getLookupItem("employee")
  //         .then((employeeList: any) => {
  //           // Find the employee by staffId
  //           const employee = employeeList.find(
  //             (employee: any) => employee.staff_id_num === role
  //           );
  //           setDbData(employee);
  //         })
  //         .catch((error: any) => {
  //           console.error("Error fetching employee data: ", error);
  //         });
  //     }
  //     getLookupItem("workday").then((workday_data: any) => {
  //         getLookupItem("holiday").then((holiday_data: any) => {
  //             setHolidayData(holiday_data);
  //             setWorkdayData(workday_data);
  //         });
  //     });
  //   }, [role]);

  // To fetch user's ID and data for Form rendering
  // useEffect(() => {
  //     Promise.all([getLookupItem("employee")]).then((data: any) => {
  //         const object: any = {};
  //         const final_object: any = {};
  //         object["employee_id"] = data[0];
  //         // const _object = object["employee_id"].filter((item: any) => item._id === userData._id);
  //         const _object = userData;
  //         final_object["employee_id"] = _object;
  //         setDbData(final_object);
  //     });

  // }, []);

  const handleLeaveApplication = () => {
    const formList = getFormOptionList(tabKey, "configuration");

    // const handleFinish = () => {
    //     setShowLeaveApplication(false);
    //     leaveform.resetFields();
    //     setImgNameUUIDList([]);
    //     setRefreshData(!refreshData);
    // };

    return (
      <LeaveApplication
        role={role}
        userData={userData}
        // activeRowKey={activeRowKey}
        showLeaveApplication={showLeaveApplication}
        setShowLeaveApplication={setShowLeaveApplication}
        formList={formList}
        leaveform={leaveform}
        imgNameUUIDList={imgNameUUIDList}
        setImgNameUUIDList={setImgNameUUIDList}
        workdayData={workdayData}
        holidayData={holidayData}
        dbData={dbData["employee_id"]}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        // setInputType={setInputType}
        // handleFinish={handleFinish}
        // handleCancel={handleCancel}
        // handleReset={handleReset}
      />
    );
  };

  const fetchData = () => {
    if (holidayData && workdayData) {
      //console.log("1-tabKey", tabKey);
      setReady(false);
      window.addEventListener("resize", () => {
        calculateTableHeight();
      });
      Emitter.emit("loading", null);
      let columns: any = getColumnList(tabKey, handleSearch, subTabKey);
      setAddButtonProp(getAddButtonProp(tabKey));

      const itemKey =
        tabKey === "employee"
          ? "employee"
          : tabKey === "application"
          ? "leave"
          : tabKey;

      getLookupItem(itemKey)
        .then((_data: any) => {
          let data: any = [];
          let key = columns[0].dataIndex;
          if (tabKey == "employee") {
            data = _data;
          } else if (tabKey == "application") {
            if (subTabKey == "all_leave") {
              data = _data.filter(
                (item: any) => item.employee_id == userData._id
              );
            } else {
              data = _data;
            }
          } else {
            data = _data;
          }

          if (data) {
            data = data
              .map((element: any) => {
                return {
                  ...element,
                };
              })
              .sort((a: any, b: any) => a[key].localeCompare(b[key]));

            data = tabKey === "application" ? data.reverse() : data;

            if (tabKey === "application") {
              data = data.map((element: any) => {
                let duration = "0";
                if (element.days === 0 || !element.days) {
                  duration = "0 day";
                } else {
                  const duration_in_days: any = daysCalculation(
                    workdayData,
                    holidayData,
                    element.start_date,
                    element.end_date,
                    element.half_days
                  );
                  duration =
                    duration_in_days <= 1
                      ? `${duration_in_days} day`
                      : `${duration_in_days} days`;
                }

                return { ...element, duration: duration };
              });
            }

            // console.log("1-data11", data);
            let renderedColumns = [];

            if (columns) {
              renderedColumns = columns.map((element: any) => {
                if (element.isActive == true) {
                  element.render = (text: any, record: any) => {
                    const id = record._id || record.uuid || record.group_uuid;
                    const employee_name = record.fullname_nric;
                    if (text) {
                      return (
                        <a
                          onClick={() => {
                            if (tabKey == "employee") {
                              navigate("configuration", {
                                replace: false,
                                state: {
                                  tabKey,
                                  activeRowKey: id,
                                  name: employee_name,
                                },
                              });
                            } else if (tabKey == "application") {
                              // console.log(
                              //     "showModal active"
                              // );
                              setSideContainerOpen(true);
                              setActiveRowKey(id);
                              setActiveRecord(record);
                            }
                          }}
                          className="table-clickable-text"
                        >
                          {text}
                        </a>
                      );
                    }
                  };
                }

                return element;
              });
              setTableColumns(renderedColumns);
              setDataTable(data);
            }
          } else {
            setTableColumns([]);
            setDataTable([]);
          }
        })
        .catch((e: any) => {
          //console.log(e);
          setTableColumns([]);
          setDataTable([]);
        })
        .finally(() => {
          setReady(true);
          calculateTableHeight();
          setFilteredDataTable(null);
          setSearchRecord([]);
          setTempRecord(null);
          Emitter.emit("finish-loading", null);
        });
    }
  };

  useEffect(() => {
    fetchData();
    socket.on("refresh-leave-data", () => {
      fetchData();
    });
  }, [tabKey, subTabKey, refreshData, holidayData, workdayData]);

  const getSubTabKeys = () => {
    return [
      {
        label: "Applied Leaves",
        key: "all_leave",
      },
    ];
  };

  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        maxHeight: "100%",
        overflow: "hidden",
      }}
    >
      <div
        className="main-container"
        style={{
          width: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          display: sideContainerOpen && isMobile ? "none" : "flex",
          flexDirection: "column",
          borderRadius: "3px",
        }}
      >
        <div className="generic-header" style={{ height: "50px" }}>
          {/* Configurations */}
          {/* {tabKey.charAt(0).toUpperCase() + tabKey.slice(1)} */}
          {containerTitle}
        </div>
        <div
          className="generic-content"
          style={{
            flex: "1",
            gap: "0px",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            flexWrap: "nowrap",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Tabs
            style={{ width: isMobile ? "100%" : "auto" }}
            tabPosition={isMobile ? "top" : "left"}
            size={isMobile ? "small" : "large"}
            onChange={handleTabChange}
            activeKey={subTabKey}
            items={getSubTabKeys()}
          ></Tabs>
          <div
            style={{
              flex: "1",
              height: "100%",
              display: "flex",
              maxWidth: "100%",
              overflow: "hidden",
              flexDirection: "column",
            }}
          >
            {" "}
            <div
              className="generic-table-header"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <span
                style={{
                  marginRight: "auto",
                  minHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* {tabKey == "employee" &&
                                    tabKey.charAt(0).toUpperCase() +
                                        tabKey.split("_").join(" ").slice(1)}{" "} */}
                {tabKey == "application" &&
                  subTabKey.charAt(0).toUpperCase() +
                    subTabKey.split("_").join(" ").slice(1)}{" "}
                directory
              </span>

              {/* {addButtonProp ? (
								<Dropdown
									disabled={sideContainerOpen}
									menu={{
										items: addButtonProp,
										onClick: (ev: any) => {
											navigate("configuration", {
												replace: false,
												state: { tabKey },
											});
											// resetForm();
											// setFormOption(ev.key);
											// setActiveRowKey(false);
											// setSideContainerOpen(true);
										},
									}}
									placement="bottomLeft"
								>
									<Button>+ Add</Button>
								</Dropdown>
							) : (
								<Button
									onClick={() => {
										navigate("configuration", {
											replace: false,
											state: { tabKey, name: "Add new employee" },
										});
									}}
									// style={tabKey == "country" ? { display: "none" } : {}}
									disabled={sideContainerOpen}
								>
									+ Add
								</Button>
							)} */}
              {/* {tabKey == "employee" && (
                                <Button
                                    onClick={() => {
                                        navigate("configuration", {
                                            replace: false,
                                            state: { tabKey, name: "Add new employee" },
                                        });
                                    }}
                                    // style={tabKey == "country" ? { display: "none" } : {}}
                                    disabled={sideContainerOpen}
                                >
                                    + Add
                                </Button>
                            )} */}
              {tabKey == "application" && subTabKey == "all_leave" && (
                <Button
                  onClick={() => {
                    switch (tabKey) {
                      default:
                        setShowLeaveApplication(true);
                        break;
                    }
                  }}
                  // style={tabKey == "country" ? { display: "none" } : {}}
                  // disabled={sideContainerOpen}
                >
                  + Add
                </Button>
              )}
            </div>
            <div
              id="table-container"
              style={{
                flex: 1,
                maxHeight: "100%",
                overflow: "hidden",
              }}
            >
              {ready && (
                <Table
                  rowClassName={(record: any) =>
                    record?.uuid === activeRowKey ||
                    `${record.group_uuid}-${record.tag_uuid}` ===
                      activeGroupKey ||
                    (record.group_uuid === activeRowKey && !record.uuid)
                      ? "antd-table-row-active"
                      : ""
                  }
                  pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                  }}
                  columns={tableColumns}
                  expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expand: any, record: any) => {
                      let keys = [...expandedRowKeys];
                      if (expand) {
                        keys.push(record.uuid);
                      } else {
                        let index = keys.findIndex(
                          (item: any) => item == record.uuid
                        );
                        if (index != -1) {
                          keys.splice(index, 1);
                        }
                      }
                      setExpandRowKey(keys);
                    },
                  }}
                  scroll={
                    tableHeight && {
                      y: tableHeight,
                      x: "0px",
                    }
                  }
                  dataSource={filteredDataTable || dataTable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {setSideContainer()}

      {handleLeaveApplication()}
      {RejectionModal()}
      {/* {LeaveConfiguration()} */}
      {/* <LeaveApplication /> */}
    </div>
    // </div>
  );
};

// LookupManager.propTypes = {
// 	params: PropTypes.any,
// };

export default UserApplication;
function setCurrent(arg0: () => any) {
  throw new Error("Function not implemented.");
}
