import axios from "axios";
// import store from "../../state/store";
import { AuthHeader, url, serverConnection } from "../../utils/_exports";
import { local } from "d3";
import { getLookupItem } from "../../utils/lookup_list";
import { DataArray } from "@mui/icons-material";

export const getToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}/data`, {
        withCredentials: true,
      })
      .then((res: any) => {
        // console.log(res.data);
        if (res.data.idToken === "undefined") {
          window.location.href = "/";
        } else {
          if (
            !localStorage.getItem(`idToken`) ||
            !localStorage.getItem(`accessToken`)
          ) {
            localStorage.setItem(`idToken`, res.data.idToken);
            localStorage.setItem(`accessToken`, res.data.accessToken);
            localStorage.setItem(`redirectURL`, res.data.redirectURL);

            resolve(res);
          } else {
            resolve("There is existing Tokens");
          }
        }
      })
      .catch((err: any) => {
        //console.log(err);
        reject(err);
      });
  });
};

export const refreshToken = (email: any) => {
  console.log("HELLO", url);
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}/data/refresh?email=${email}`, {
        withCredentials: true,
      })
      .then((res: any) => {
        if (res.data) {
          localStorage.setItem(`accessToken`, res.data);
          resolve(res.data);
        } else {
          //console.log("Cannot set Access Token of undefined");
          reject(false);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const getErrorMessage = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}/data/getErrorMessage`, { withCredentials: true })
      .then((res: any) => {
        if (res.data.errorMessage) {
          localStorage.setItem("LoginMessage", res.data.errorMessage);

          resolve(res);
        } else {
          //console.log("Error Message is Undefined");
        }
      })
      .catch((error: any) => {
        //console.log("Error getting error message.", error);
        reject(error);
      });
  });
};

export const sendEmail = (data: any, content: any, recipientEmail: any) => {
  return new Promise((resolve, reject) => {
    data.content = content;
    data.receiver = recipientEmail;
    axios
      .post(`${serverConnection}/sendemail/`, data, AuthHeader)
      .then((_data: any) => {
        resolve(_data?.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// export const getPbiToken = (token: any = null) => {
// 	return new Promise((resolve, reject) => {
// 		let header: any = null;

// 		if (token) {
// 			header = {
// 				headers: {
// 					Authorization: `${token}`,
// 				},
// 			};
// 		} else {
// 			header = AuthHeader;
// 		}
// 		axios
// 			.get(`${url}/pbitoken`, header)
// 			.then((res: any) => {
// 				if (res.data.accessToken) {
// 					localStorage.setItem(
// 						`${Tenant}:pbiToken`,
// 						"Bearer ".concat(res.data.accessToken)
// 					);
// 					resolve("Bearer ".concat(res.data.accessToken));
// 				} else {
// 					reject("Token returned Undefined");
// 				}
// 			})
// 			.catch((err: any) => {
// 				reject(err);
// 			});
// 	});
// };
