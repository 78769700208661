import { pdfjs } from "react-pdf";

export const setupPdfJsWorker = () => {
	let WORKER_URL = "";

	if (!pdfjs.GlobalWorkerOptions.workerSrc) {
		WORKER_URL = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
		pdfjs.GlobalWorkerOptions.workerSrc = WORKER_URL;
	}
};
