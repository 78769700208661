import React, { useContext, useEffect, useMemo, useState } from "react";
import { Space, Tabs, Table, Button } from "antd";

import { HomeContext } from "./Home";
import { getLeaveList } from "../services/api-server/leave";
import { getLookupItem } from "../utils/lookup_list";
import LeaveOverview from "./LeaveOverview";
// import LeaveManager from "./LeaveManager";
import LookupManager from "./LookupManager";
import LeaveConfig from "./LeaveConfig";
import LeaveReport from "./LeaveReport";

import UserApplication from "./UserApplication";

function LeaveContainer(props: any) {
  const context: any = useContext(HomeContext);
  const [tabKey, setTabKey] = useState<any>("leaveOverview");
  const [subTabKey, setSubTabKey] = useState<any>("all_leave");

  const [sideContainerOpen, setSideContainerOpen] = useState<boolean>(false);
  const [activeRowKey, setActiveRowKey] = useState<any>(null);
  const [activeRecord, setActiveRecord] = useState<any>(null);
  const [holidayData, setHolidayData] = useState<any>([]);
  const [workdayData, setWorkdayData] = useState<any>([]);

  const role = props.params.userRole[0];
  const userData = props.params.user;

  useEffect(() => {
    if (tabKey !== "leaveManager") {
      setSubTabKey("all_leave");
    }
  }, [tabKey]);

  const changeContent = () => {
    switch (tabKey) {
      case "leaveOverview":
        return (
          <LeaveOverview
            role={role}
            userData={userData}
            setTabKey={setTabKey}
            setSubTabKey={setSubTabKey}
            setSideContainerOpen={setSideContainerOpen}
            setActiveRowKey={setActiveRowKey}
            setActiveRecord={setActiveRecord}
            holidayData={holidayData}
            workdayData={workdayData}
            // setHolidayData={setHolidayData}
            // setWorkdayData={setWorkdayData}
          />
        );
      case "leaveManager":
        return (
          <LookupManager
            role={role}
            userData={userData}
            tabKey={tabKey}
            subTabKey={subTabKey}
            sideContainerOpen={sideContainerOpen}
            activeRowKey={activeRowKey}
            activeRecord={activeRecord}
            holidayData={holidayData}
            workdayData={workdayData}
            containerTitle={props.params.propTitle + " Management"}
          />
        );
      // return <LeaveManager />;
      case "leaveConfiguration":
        return <LeaveConfig />;
      case "leaveReport":
        return <LeaveReport />;
      case "application":
        return (
          <UserApplication
            role={role}
            userData={userData}
            tabKey={tabKey}
            subTabKey={subTabKey}
            sideContainerOpen={sideContainerOpen}
            activeRowKey={activeRowKey}
            activeRecord={activeRecord}
            holidayData={holidayData}
            workdayData={workdayData}
            containerTitle={props.params.propTitle}
          />
        );

      default:
        return <></>;
    }
  };

  const handleTabChange = async (key: any) => {
    try {
      await context.handlePageChange();
      setTabKey(key);
    } catch {}
  };

  useEffect(() => {
    getLookupItem("workday").then((workday_data: any) => {
      getLookupItem("holiday").then((holiday_data: any) => {
        setHolidayData(holiday_data);
        setWorkdayData(workday_data);
      });
    });
    //console.log("role", role);
    //console.log("userID", userData);
  }, []);

  return (
    <div className="generic-container" style={{ gap: "20px" }}>
      <Space direction="vertical">
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "rgba(0, 0, 0, 0.85)",
          }}
        >
          {props.params.propTitle}
        </span>
      </Space>

      <Tabs
        onChange={handleTabChange}
        activeKey={tabKey}
        items={
          role === "admin"
            ? [
                { label: "Overview", key: "leaveOverview" },
                { label: "Management", key: "leaveManager" },
                //   {
                //       label: "Configuration",
                //       key: "leaveConfiguration",
                //   },
                { label: "Report", key: "leaveReport" },
              ]
            : [
                { label: "Overview", key: "leaveOverview" },
                { label: "Application", key: "application" },
              ]
        }
      ></Tabs>

      {changeContent()}
    </div>
  );
}

export default LeaveContainer;
