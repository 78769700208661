import { useEffect, useState } from "react";
import { Form, Collapse, CollapseProps, Table } from "antd";
import Emitter from "../../utils/emitter";
import { getLookupItem } from "../../utils/lookup_list";
import { isMobile } from "react-device-detect";
import SearchButton from "../../components/SearchButton";

const EmployeeLeave = (props: any) => {
  const formRef = props.formRef;
  const [resumeFormRef] = Form.useForm();
  const data = props.data["employees"] || {};
  const leaverecordData = props.leaverecordData;
  const activeRowKey = props.activeRowKey;
  const [employeeData, setEmployeeData] = useState<any>(null);
  const [employeeLeaveBalance, setEmployeeLeaveBalance] = useState<any>(null);
  const [refreshResume, setRefreshResume] = useState<any>(0);
  const [tempRecord, setTempRecord] = useState<any>(null);
  const [searchRecord, setSearchRecord] = useState<any>([]);
  const [filteredDataTable, setFilteredDataTable] = useState<any>(null);

  const [resumeModalVisibility, setResumeModalVisibility] =
    useState<boolean>(false);

  const fetchData = () => {
    if (activeRowKey) {
      getLookupItem("employee").then((data: any) => {
        const employee = data.find((item: any) => item._id == activeRowKey);
        setEmployeeData(employee);
      });
      let employee_record = leaverecordData.filter(
        (item: any) => item.employee_id == activeRowKey
      );
      const groupedByYear = employee_record.reduce(
        (accumulator: any, currentItem: any) => {
          const year = currentItem.year;
          if (!accumulator[year]) {
            accumulator[year] = [];
          }
          accumulator[year].push(currentItem);
          return accumulator;
        },
        {}
      );
      setEmployeeLeaveBalance(groupedByYear);
      // console.log("yy", groupedByYear);
    }
  };

  useEffect(() => {
    fetchData();
    // socket.on("refresh-employee-data", () => {
    //     fetchData();
    // });
  }, [refreshResume]);

  const onChange = (key: string | string[]) => {
    console.log("key", key);
  };

  const handleSearch = (record: any) => {
    console.log("rec", record);
    setTempRecord(record);
  };

  const columns = [
    {
      title: isMobile ? "Leave Type" : "Leave Type",
      // <div onClick={(e) => e.stopPropagation()}>
      //   <SearchButton
      //     handleSearch={handleSearch}
      //     headerName="Leave Type"
      //     columnKey="leave_type"
      //     key="leave_type"
      //   />
      // </div>
      dataIndex: "leave_type",
      key: "leave_type",
      // width: "30%",
      render: (text: string) => (
        <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
          {text}
        </span>
      ),
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => {
      //   return a["leave_type"]
      //     .toString()
      //     .localeCompare(b["leave_type"].toString());
      // },
    },
    {
      title: isMobile ? "Leave Entitled" : "Leave Entitled",
      // (
      //   <div onClick={(e) => e.stopPropagation()}>
      //     <SearchButton
      //       handleSearch={handleSearch}
      //       headerName="Leave Entitled"
      //       columnKey="entitled"
      //       key="entitled"
      //     />
      //   </div>
      // ),
      dataIndex: "entitled",
      key: "entitled",
      // width: "40%",
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => {
      //   return a["entitled"] - b["entitled"];
      // },
    },
    {
      title: isMobile ? "Leave Used" : "Leave Used",
      // (
      //   <div onClick={(e) => e.stopPropagation()}>
      //     <SearchButton
      //       handleSearch={handleSearch}
      //       headerName="Leave Used"
      //       columnKey="used"
      //       key="used"
      //     />
      //   </div>
      // ),
      dataIndex: "used",
      key: "used",
      // width: "30%",
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => {
      //   return a["used"] - b["used"];
      // },
    },
    {
      title: isMobile ? "Leave Pending" : "Leave Pending",
      // (
      //   <div onClick={(e) => e.stopPropagation()}>
      //     <SearchButton
      //       handleSearch={handleSearch}
      //       headerName="Leave Pending"
      //       columnKey="pending"
      //       key="pending"
      //     />
      //   </div>
      // ),
      dataIndex: "pending",
      key: "pending",
      // width: "40%",
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => {
      //   return a["pending"] - b["pending"];
      // },
    },
    {
      title: isMobile ? "Leave Balance" : "Leave Balance",
      //  (
      //   <div onClick={(e) => e.stopPropagation()}>
      //     <SearchButton
      //       handleSearch={handleSearch}
      //       headerName="Leave Balance"
      //       columnKey="balance"
      //       key="balance"
      //     />
      //   </div>
      // ),
      dataIndex: "balance",
      key: "balance",
      render: (text: string) => (
        <span style={{ fontWeight: "bold" }}>{text}</span>
      ),
      // width: "40%",
      showSorterTooltip: false,
      // sorter: (a: any, b: any) => {
      //   return a["balance"] - b["balance"];
      // },
    },
  ];

  return (
    <>
      <div className="generic-header" style={{ height: "50px" }}>
        Employee Leave Balance
      </div>
      <div className="generic-content" style={{ flex: "1", overflowY: "auto" }}>
        <div style={{ flex: "1", position: "relative" }}>
          <Form
            form={formRef}
            layout="vertical"
            name="employee_resume"
            style={{
              width: "100%",
              padding: "10px",
              fontWeight: "bold",
            }}
          >
            {employeeData &&
              employeeData.leave_entitled &&
              employeeData.leave_entitled.length > 0 && (
                <Collapse
                  items={Object.keys(employeeLeaveBalance)
                    .reverse()
                    .map((year, index) => ({
                      key: index.toString(), // Key as index
                      label: year, // Year as the label
                      children: (
                        <div>
                          <p>
                            Claimable balance:{" "}
                            {employeeLeaveBalance[year][0].claims}
                          </p>
                          <Table
                            columns={columns}
                            dataSource={employeeLeaveBalance[year].map(
                              (leave: any, idx: number) => ({
                                key: idx, // Key for each row
                                leave_type: leave.leave_type,
                                entitled: leave.entitled,
                                used: leave.used,
                                pending: leave.pending,
                                balance:
                                  leave.entitled - leave.used - leave.pending,
                              })
                            )}
                            pagination={false} // Disable pagination if you don't need it
                          />
                        </div>
                      ), // Detailed info as children
                    }))}
                  defaultActiveKey={["0"]}
                  onChange={onChange}
                />
              )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default EmployeeLeave;
