import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "leave_record";

// position GET route
export const getLeaveRecordList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${serverConnection}/${apiURL}/`, { ...AuthHeader })
      .then((_data: any) => {
        if (_data?.data.data.data && _data.data.data.data.length) {
          resolve(_data.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// position GET route by position id
export const getLeaveRecordListById = (employee_id: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${serverConnection}/${apiURL}/`, {
        params: { employee_id },
        ...AuthHeader,
      })
      .then((_data: any) => {
        if (_data?.data.data.data && _data.data.data.data.length) {
          resolve(_data.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

export const getLeaveRecordByQuery = (query: any) => {
  return new Promise((resolve, reject) => {
    axios
      // .get(`${url}/${apiURL}/query`, {
      .get(`${serverConnection}/${apiURL}/`, {
        query: query,
        ...AuthHeader,
      })
      .then((_data: any) => {
        console.log("data", _data);
        if (_data?.data.data.data) {
          resolve(_data.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// position POST route
export const addLeaveRecordItem = (data: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
      .then((_data: any) => {
        resolve(_data.data.data.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

// position PUT route
export const updateLeaveRecordItem = (_ids: Array<any>, data: any) => {
  const _data = {
    query: { _id: _ids[0] },
    data: data,
  };
  console.log("data", data);
  return new Promise((resolve, reject) => {
    axios
      .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
      .then((_data: any) => {
        resolve(_data.data.data.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};
