import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Space, Tabs } from "antd";
import LookupManager from "./LookupManager";
import { setContainer } from "../utils/containers";
import { tab } from "@testing-library/user-event/dist/tab";
import { HomeContext } from "./Home";
import zIndex from "@mui/material/styles/zIndex";

function GenericTabContainer(props: any) {
	const role = props.params?.userRole[0];
	const context: any = useContext(HomeContext);
	const [tabkey, setTabKey] = useState("lookup-manager");
	const changeContent = () => {
		switch (tabkey) {
			case "lookup-manager":
				return (
					<LookupManager
						tabKey={"employee"}
						subTabKey={"employee"}
						containerTitle={"Employee Configuration"}
					/>
				);
			case "claims":
				return (
					<>
						<h1 style={{ color: "black" }}>Claims</h1>
					</>
				);
			case "ot_payments":
				return (
					<>
						<h1 style={{ zIndex: 1, color: "black" }}>OT Payments</h1>
					</>
				);
			case "e_payslip":
				return (
					<>
						<h1 style={{ zIndex: 1, color: "black" }}>E-payslip</h1>
					</>
				);
			default:
				return <></>;
		}
	};

	const handleTabChange = async (key: any) => {
		try {
			await context.handlePageChange();
			setTabKey(key);
		} catch {}
	};

	return (
		<div className="generic-container" style={{ gap: "20px" }}>
			<Space direction="vertical">
				<span
					style={{
						fontSize: "20px",
						fontWeight: "600",
						color: "rgba(0, 0, 0, 0.85)",
					}}
				>
					{props.params.propTitle}
				</span>
			</Space>
			<Tabs
				onChange={handleTabChange}
				activeKey={tabkey}
				items={[
					{ label: "Employee", key: "lookup-manager" },
					// { label: "Claims", key: "claims" },
					// { label: "OT Payments", key: "ot_payments" },
					// { label: "E-payslip", key: "e_payslip" },
				]}
			></Tabs>

			{changeContent()}
		</div>
	);
}

export default GenericTabContainer;
