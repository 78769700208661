import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "position";

// position GET route
export const getPositionList = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, { ...AuthHeader })
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// position GET route by position id
export const getPositionListById = (position_id: string) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, {
                params: { position_id },
                ...AuthHeader,
            })
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// position POST route
export const addPositionItem = (data: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// position PUT route
export const updatePositionItem = (_ids: Array<any>, data: any) => {
    const _data = {
        query: { _id: _ids[0] },
        data: data,
    };
    return new Promise((resolve, reject) => {
        axios
            .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// position DELETE route
export const deletePositionItem = (_id: any) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${serverConnection}/${apiURL}/${_id}`, {
                params: {
                    _id: _id,
                },
                ...AuthHeader,
            })
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
