import React, { useState, useEffect, useRef } from "react";

import { Button, Input, Space } from "antd";

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

import { getColumnList, getLookupItem } from "../utils/lookup_list";

const SearchButton = (props: any) => {
    const [searchState, setSearchState] = useState<any>(false);
    const [searchValue, setSearchValue] = useState<any>(null);

    const handleSearch = (e: any) => {
        if (e.type == "change") {
            setSearchValue(e.target.value);
        } else {
            props.handleSearch({
                columnKey: props.columnKey,
                value: "",
            });
            setSearchValue(null);
            setSearchState(false);
        }
    };

    const handleSearchEnter = (e: any) => {
        props.handleSearch({
            columnKey: props.columnKey,
            value: e.target.value,
        });
        setSearchState(false);
    };

    return searchState ? (
        <Input
            className="input-clearable"
            onChange={handleSearch}
            onBlur={handleSearchEnter}
            onPressEnter={handleSearchEnter}
            value={searchValue}
            allowClear
            autoFocus
            prefix={<SearchOutlined />}
        />
    ) : (
        <Button
            onClick={() => {
                setSearchState(true);
            }}
            style={{
                border: "none",
                boxShadow: "none",
                background: "transparent",
            }}
            icon={<SearchOutlined />}
        >
            {props.headerName}{" "}
            {searchValue != null && searchValue !== "" && `(${searchValue})`}
        </Button>
    );
};

export default SearchButton;
