import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "approval_workflow";

// approval_workflow GET route
export const getApprovalWorkflowList = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, { ...AuthHeader })
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// approval_workflow GET route by approval_workflow id
export const getApprovalWorkflowListById = (approval_workflow_id: string) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, {
                params: { approval_workflow_id },
                ...AuthHeader,
            })
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//approval_workflow POST route
export const addApprovalWorkflowItem = (data: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//approval_workflow PUT route
export const updateApprovalWorkflowItem = (_ids: Array<any>, data: any) => {
    const _data = {
        approval_workflow_id: _ids,
        ...data,
    };
    return new Promise((resolve, reject) => {
        axios
            .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//approval_workflow DELETE route
export const deleteApprovalWorkflowItem = (approval_workflow_id: string) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${serverConnection}/${apiURL}/`, {
                data: { approval_workflow_id },
                ...AuthHeader,
            })
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
