import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "employee";

export const getEmployeeList = () => {
  return new Promise((resolve, reject) => {
    axios
      // .get(`${url}/${apiURL}/`, {
      .get(`${serverConnection}/${apiURL}/`, {
        ...AuthHeader,
      })
      .then((_data: any) => {
        // console.log(_data.data.data.data);
        if (_data?.data.data.data && _data.data.data.data.length) {
          //console.log("employee_data", _data);
          resolve(_data.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

export const getEmployeeByID = (_id: any) => {
  return new Promise((resolve, reject) => {
    axios
      // .get(`${url}/${apiURL}/${_id}`, {
      .get(`${serverConnection}/${apiURL}/${_id}`, {
        ...AuthHeader,
      })
      .then((_data: any) => {
        if (_data?.data.data.data) {
          resolve(_data.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

export const getEmployeeByQuery = (query: any) => {
  return new Promise((resolve, reject) => {
    axios
      // .get(`${url}/${apiURL}/query`, {
      .get(`${serverConnection}/${apiURL}/`, {
        query: query,
        ...AuthHeader,
      })
      .then((_data: any) => {
        if (_data?.data.data.data) {
          resolve(_data.data.data.data);
        } else {
          resolve(null);
        }
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

export const addEmployeeItem = (data: any) => {
  return new Promise((resolve, reject) => {
    //console.log("Test add user Item2", data);
    axios
      // .post(`${url}/${apiURL}/`, data, AuthHeader)
      .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
      .then((_data: any) => {
        //console.log("Test add userItem 3", _data);
        resolve(_data.data.data.data);
      })
      .catch((e: any) => {
        //console.log("Test add userItem 4", e);
        reject(e);
      });
  });
};

// export const updateEmployeeItem = (_id: any, data: any, prevData: any) => {
export const updateEmployeeItem = (_ids: Array<any>, data: any) => {
  const _data: any = {
    // query: { _id: { $in: _ids } },
    query: { _id: _ids[0] },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axios
      // .put(`${url}/${apiURL}/`, _data, AuthHeader)
      .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
      .then((_data: any) => {
        //console.log(_data);
        resolve(_data.data.data.data);
      })
      .catch((e: any) => {
        //console.log(e);
        reject(e);
      });
  });
};

export const updateEmployeeItemResume = (_ids: Array<any>, data: any) => {
  const _data = {
    // query: { _id: { $in: _ids } },
    query: { _id: _ids[0] },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axios
      // .put(`${url}/${apiURL}/`, _data, AuthHeader)
      .put(`${serverConnection}/${apiURL}/saveFile`, _data, AuthHeader)
      .then((_data: any) => {
        //console.log(_data);
        resolve(_data.data.data.data);
      })
      .catch((e: any) => {
        //console.log(e);
        reject(e);
      });
  });
};

export const deleteEmployeeItem = (_id: any) => {
  return new Promise((resolve, reject) => {
    axios
      // .delete(`${url}/${apiURL}/${_id}`, {
      .delete(`${serverConnection}/${apiURL}/${_id}`, {
        params: {
          _id: _id,
        },
        ...AuthHeader,
      })
      .then((_data) => {
        resolve(true);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};
