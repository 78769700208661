import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Space,
  Tabs,
  Tag,
  Divider,
  Upload,
} from "antd";
import Emitter from "../utils/emitter";
import { useForm, useWatch } from "antd/es/form/Form";
import "../assets/css/projects.css";
import FormButtonSave from "../components/FormButtonSave";
import FormButtonCancel from "../components/FormButtonCancel";
import { projectTest, projectType, regionType } from "../utils/test";
// import { getBusinessUnitList } from "../services/api-server/default";
import { countryCodes, countryList } from "../utils/countryCodes";
import {
  addEmployeeItem,
  updateEmployeeItem,
  deleteEmployeeItem,
} from "../services/api-server/employee";
import { useLocation, useNavigate, useParams } from "react-router";
import { GetAntIcon } from "../utils/ant_icons";
import {
  ExclamationCircleOutlined,
  WarningOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import LookupMissingTooltip from "../components/LookupMissingTooltip";
// import ProjectConfiguration from "../projectTabs/ProjectConfiguration";
import { HomeContext } from "./Home";
// import ProjectDetails from "../projectTabs/ProjectDetails";
import EmployeeConfiguration from "./forms/EmployeeConfigurations";
import EmployeeResume from "./forms/EmployeeResume";
import EmployeeLeave from "./forms/EmployeeLeave";
import {
  deleteUserItem,
  getAllUsers,
  registerUserAcc,
  updateUserItem,
} from "../services/api-server/user_acc";
import { getLookupItem } from "../utils/lookup_list";
import { getLeaveTypeList } from "../services/api-server/leavetype";
import moment from "moment";
import dayjs from "dayjs";
import { getPositionList } from "../services/api-server/position";
import { getLeaveRecordList } from "../services/api-server/leave_record";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import { iteratee } from "lodash";
import { deleteTraces } from "plotly.js";
import { socket } from "../utils/socket";

const { Option } = Select;

/*
	- currentProject is the project code of the current project
*/
function Projects(props: any) {
  const context: any = useContext(HomeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [allData, setAllData] = useState<any>({});
  const [activeRowKey, setActiveRowKey] = useState<any>(null);
  const [employeeType, setEmployeeType] = useState<any>("employee");

  const [currentProject, setCurrentProject] = useState<any>(null);
  const [currentEmployee, setCurrentEmployee] = useState<any>(null);
  const [formRef]: any = useForm();
  const [formReset, setFormReset] = useState<boolean>(true);
  const [businessUnits, setBusinessUnits] = useState<any>(null);
  const [users, setUsers] = useState<any>(null);
  const [employees, setEmployees] = useState<any>(null);
  const [emptyModalOpen, setEmptyModalOpen] = useState<any>(null);

  const [initialForm, setInitialForm] = useState<any>(null);
  const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<any>(false);
  const [tabkey, setTabKey] = useState<any>("personal");
  const [addNew, setAddNew] = useState<any>(false);

  const [history, setHistory] = useState<any>([]);
  const [leavetypeData, setLeavetypeData] = useState<any>([]);
  const [userData, setUserData] = useState<any>([]);
  const [positionData, setPositionData] = useState<any>([]);
  const [leaverecordData, setLeaveRecordData] = useState<any>([]);
  const [refreshdata, setRefreshData] = useState(false);
  // Hooks for End Date
  const [endDateVisibility, setEndDateVisibility] = useState(false);

  // Load all the data needed for the form
  useEffect(() => {
    Emitter.emit("loading", null);
    let allData: any = {};

    Promise.all([
      getLeaveTypeList().then((_data: any) => {
        // console.log("Test leave data", _data);
        setLeavetypeData(_data);
      }),
    ]);

    Promise.all([
      getAllUsers().then((_data: any) => {
        // console.log("Test user data", _data);
        setUserData(_data);
      }),
    ]);

    Promise.all([
      getPositionList().then((_data: any) => {
        // console.log("Test user data", _data);
        setPositionData(_data);
      }),
    ]);

    Promise.all([
      getLeaveRecordList().then((_data: any) => {
        // console.log("Test user data", _data);
        const filteredData = _data.filter(
          (record: any) => record.entitled > 0 && record.leave_status == true
        );
        setLeaveRecordData(filteredData);
      }),
    ]);

    Promise.all([
      // getAllUsers().then((_data: any) => {
      // 	if (_data) {
      // 		let data = _data.map((item: any) => {
      // 			item.key = item._id;
      // 			item.value = item.name;
      // 			item.label = item.name;
      // 			return item;
      // 		});
      // 		data = sortData(data);
      // 		allData.users = data;
      // 		setUsers(data);
      // 	}
      // }),
      getLookupItem("employee").then((_data: any) => {
        //console.log("employee list", _data);
        if (_data) {
          let data = _data.map((item: any) => {
            item.key = item._id;
            item.value = item.fullname_nric;
            item.label = item.fullname_nric;
            return item;
          });
          data = sortData(data);
          allData.employees = data;
          setEmployees(data);
        }
      }),
    ])
      .then((data: any) => {
        // console.log(allData);
        setAllData(allData);
      })
      .finally(() => {
        Emitter.emit("configuration", location.state.name); // set breadcrumb
        Emitter.emit("finish-loading", null);
        if (location.state.subTabKey) {
          setEmployeeType(location.state.subTabKey);
        }
        if (location.state.activeRowKey) {
          setActiveRowKey(location.state.activeRowKey);
        }
        if (location.state.name) {
          setCurrentEmployee(location.state.name);
        } else {
          setCurrentEmployee("Add new employee");
        }
      });
  }, [refreshdata]);

  const fetchData = () => {
    // Use the active row key to set the current data in the form
    if (activeRowKey !== null) {
      Promise.all([
        getLookupItem("employee").then((_data: any) => {
          // console.log("employee list", _data);
          if (_data) {
            let data = _data.map((item: any) => {
              item.key = item._id;
              item.value = item.fullname_nric;
              item.label = item.fullname_nric;
              return item;
            });
            data = sortData(data);
            allData.employees = data;
            setEmployees(data);
          }
        }),
        // getAllUsers().then((_data: any) => {
        //     // console.log("Test user data", _data);
        //     setUserData(_data);
        // }),
      ]).then(() => {
        Promise.all([
          getAllUsers().then((_data: any) => {
            // console.log("Test user data", _data);
            setUserData(_data);
            // console.log(_data);

            // console.log(employees);
            let data = employees?.find((item: any) => item.key == activeRowKey);
            // console.log("TESTTTTT", data.date_join);
            if (data?.date_join) {
              data.date_join = dayjs(data.date_join);
            }

            if (data?.date_end) {
              data.date_end = dayjs(data.date_end);
            }

            if (data?.position === "INTERN") {
              setEmployeeType("intern");
            } else {
              setEmployeeType("employee");
            }
            // data.date_join = data.date_join
            //     ? dayjs(data.date_join)
            //     : undefined;
            const email = data?.email;
            let user_data = _data?.find((item: any) => item.email === email);
            let position_item = positionData?.find(
              (positionItem: any) =>
                positionItem.position_code === data?.position
            );
            if (user_data) {
              data = {
                ...data,
                roles: user_data.roles[0],
              };
              if (position_item) {
                data = {
                  ...data,
                  position: position_item.position_name,
                };
              }
            }
            if (data) {
              // console.log(data);
              // setCurrentProject(data.key);
              setInitialForm(data);
              formRef.setFieldsValue(data);
              // formRef.setFieldValue(
              //     "position",
              //     position_item?.position_name
              // );
              formRef.setFieldValue("roles", user_data?.roles[0]);
            }
          }),
        ]);
      });
    }
  };

  useEffect(() => {
    fetchData();
    socket.on("refresh-employee-data", () => {
      fetchData();
    });
  }, [activeRowKey, refreshdata]);

  // Reset form
  const resetForm = (values: any = null, data: any = null) => {
    if (formRef) {
      setFormReset(true);
      formRef.resetFields();
      formRef.setFieldsValue(values);
      // console.log(values);
      setInitialForm(values);
    }
  };

  // Function to sort array of objects alphabetically based on property's name
  const sortData = (data: any) => {
    data.sort((a: any, b: any) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return data;
  };

  // Handle Tab change. Default is 'default'
  const handleTabChange = async (key: any) => {
    try {
      await context.handlePageChange();
      setTabKey(key);
      setFormReset(true);
    } catch {}
  };

  // Handle form save
  const handleFormSave = (formValues: any | null = null, type: any = null) => {
    // console.log(formValues);
    let values = formValues || formRef.getFieldsValue();
    // For each values in the form, trim the leading and trailing spaces
    Object.keys(values).forEach((key) => {
      if (typeof values[key] == "string") {
        values[key] = values[key].trim();
      }
    });

    Emitter.emit("loading", null);

    //console.log("adding data", values);

    // if (currentProject != "Add new project" && currentProject) {

    if (!activeRowKey) {
      //console.log("Add data");
      switch (tabkey) {
        case "personal":
          values.modified_on = Date.now();
          const roles = values.roles;
          delete values.roles;
          values.email = values.email.toLowerCase();
          let leave_entitled_array: any = [];
          leavetypeData.forEach((item: any) => {
            const leave_entitled =
              item.leave_type_code === "annual"
                ? Number(values.num_leaves)
                : item.default_days;
            leave_entitled_array.push({
              leave_type: item.leave_type_code,
              entitled: leave_entitled,
              used: 0,
              pending: 0,
              leave_status: item.status,
            });
          });
          values.leave_entitled = leave_entitled_array;

          // if (typeof values.date_join !== "string") {
          // console.log("Test date value", typeof values.date_join);
          // values.date_join = new Date(values.date_join);
          values.date_join = moment(values.date_join.toDate()).format(
            "YYYY-MM-DD"
          );
          // values.date_join = dayjs(values.date_join);
          // }
          if (values.status === "inactive" && values.date_end) {
            values.date_end = moment(values.date_end.toDate()).format(
              "YYYY-MM-DD"
            );
          } else {
            values.date_end = undefined;
          }
          // if (
          //     values.date_end &&
          //     typeof values.date_end !== "string"
          // ) {
          // console.log("Test date value", typeof values.date_join);
          // values.date_join = new Date(values.date_join);
          // values.date_end = moment(
          //     values.date_end.toDate()
          // ).format("DD MMM YYYY");
          // values.date_join = dayjs(values.date_join);
          // }
          // values.uuid = v4();
          addEmployeeItem(values)
            .then((_data: any) => {
              // console.log("TEST ADD EMPLOYEE", _data);
              let useracc_data = {
                employee_id: _data[0]._id,
                email: values.email,
                roles: [roles],
              };
              registerUserAcc(useracc_data).then((data: any) => {
                Emitter.emit("alert", {
                  type: "success",
                  message: "Employee has been successfully added.",
                  description: "",
                  top: true,
                  closeable: false,
                  timeout: 3000,
                });
                // setRefreshData(!refreshdata);
                values.roles = roles;
                values.date_join = dayjs(values.date_join);
                if (values.status === "inactive" && values.date_end) {
                  values.date_end = dayjs(values.date_end);
                }
                // console.log(values);
                resetForm(values);
                setActiveRowKey(_data._id);
                Emitter.emit("configuration", values.fullname_nric);
                setCurrentEmployee(values.fullname_nric);
                getLookupItem("employee").then((_data: any) => {
                  if (_data) {
                    let data = _data.map((item: any) => {
                      item.key = item._id;
                      item.value = item.fullname_nric;
                      item.label = item.fullname_nric;
                      return item;
                    });

                    data = sortData(data);
                    setEmployees(data);
                    setAllData({
                      ...allData,
                      employees: data,
                    });
                  }
                });
              });
            })
            .catch((e: any) => {
              //console.log("error", e);
              if (e.response.data.detail.includes("Duplicated key")) {
                const duplicateKeyValue = extractDuplicateKeyValue(
                  e.response.data.detail
                );
                Emitter.emit("alert", {
                  type: "error",
                  message: `Duplicated key: Staff ID. Please try again.`,
                  description: "",
                  top: true,
                  closeable: false,
                  timeout: 3000,
                });
              } else {
                Emitter.emit("alert", {
                  type: "error",
                  message: e.response.data.detail + ". Please try again.",
                  description: "",
                  top: true,
                  closeable: false,
                  timeout: 3000,
                });
              }
            })
            .finally(() => {
              Emitter.emit("finish-loading", null);
            });
          break;
        default:
          break;
      }
    } else {
      //console.log("Update data");
      switch (tabkey) {
        case "personal":
          let data = employees?.find((item: any) => item.key == activeRowKey);
          values.modified_on = Date.now();
          values.email = values.email.toLowerCase();
          let roles = values.roles;
          delete values.roles;
          let leave_entitled_array: any = [];
          let i = 0;
          leavetypeData?.forEach((item: any) => {
            const leave_entitled =
              item.leave_type_code === "annual"
                ? Number(values.num_leaves)
                : item.default_days;
            leave_entitled_array.push({
              leave_type: item.leave_type_code,
              entitled: leave_entitled,
              used: data.leave_entitled[i].used,
              pending: data.leave_entitled[i].pending,
              leave_status: data.leave_entitled[i].leave_status,
            });
            i += 1;
          });
          values.leave_entitled = leave_entitled_array;
          // values.date_join = moment(values.date_join.toDate()).format(
          //     "DD MMM YYYY"
          // );
          //console.log("Test values ", values);
          // if (!values.date_join.isDayjsObject) {
          //     values.date_join = dayjs(values.date_join);
          // }
          values.date_join = moment(values.date_join.toDate()).format(
            "YYYY-MM-DD"
          );

          if (values.status === "inactive" && values.date_end) {
            values.date_end = moment(values.date_end.toDate()).format(
              "YYYY-MM-DD"
            );
          } else {
            values.date_end = undefined;
          }
          //console.log(values);
          // values.uuid = activeRowKey;
          // updateEmployeeItem(activeRowKey, values, initialForm)

          updateEmployeeItem([activeRowKey], values)
            .then((_data: any) => {
              // console.log(_data);
              const uservalue = userData?.find(
                (item: any) => item.employee_id === activeRowKey
              );
              if (uservalue.roles[0] !== roles) {
                updateUserItem(activeRowKey, {
                  email: values.email,
                  roles: [roles],
                })
                  .then(() => {
                    const payload = {
                      employee_id: activeRowKey,
                    };
                    socket.emit("pong-update-employee", payload, () => {
                      console.log("Pinging server");
                    });
                  })
                  .catch((e: any) => {
                    if (e.response.data.detail.includes("Duplicated key")) {
                      const duplicateKeyValue = extractDuplicateKeyValue(
                        e.response.data.detail
                      );
                      Emitter.emit("alert", {
                        type: "error",
                        message: `Duplicated key: ${duplicateKeyValue}. Please try again.`,
                        description: "",
                        top: true,
                        closeable: false,
                        timeout: 3000,
                      });
                    } else {
                      Emitter.emit("alert", {
                        type: "error",
                        message: e.response.data.detail + ". Please try again.",
                        description: "",
                        top: true,
                        closeable: false,
                        timeout: 3000,
                      });
                    }
                  });
              }
            })
            .then(() => {
              getAllUsers().then((_data: any) => {
                setUserData(_data);
                const uservalue = _data?.find(
                  (item: any) => item.employee_id === activeRowKey
                );

                values.roles = uservalue.roles[0];
                values.date_join = dayjs(values.date_join);
                if (values.status === "inactive" && values.date_end) {
                  values.date_end = dayjs(values.date_end);
                }
                // console.log(values);
                resetForm(values);
                Emitter.emit("configuration", values.fullname_nric);
                setCurrentEmployee(values.fullname_nric);
              });

              // getLookupItem("employee").then((_data: any) => {
              //     if (_data) {
              //         let data = _data.map((item: any) => {
              //             item.key = item._id;
              //             item.value = item.fullname_nric;
              //             item.label = item.fullname_nric;
              //             return item;
              //         });
              //         data = sortData(data);
              //         setEmployees(data);
              //         setAllData({
              //             ...allData,
              //             employees: data,
              //         });
              //     }
              // });
            })
            .catch((e: any) => {
              //console.log("error", e);
              Emitter.emit("alert", {
                type: "error",
                message: e.response.data.detail + ". Please try again.",
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
              });
            })
            .finally(() => {
              Emitter.emit("alert", {
                type: "success",
                message: "Employee has been successfully updated.",
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
              });
              Emitter.emit("finish-loading", null);

              // setRefreshData(!refreshdata);
            });
          break;
        default:
          break;
      }
    }
    // setRefreshData(!refreshdata);
  };

  // Handle form delete
  const handleFormDelete = () => {
    Emitter.emit("loading", null);
    context?.handleFormUpdating(false);
    // Emitter.emit("projectName", "Add new project");
    const values = formRef.getFieldsValue();
    // values.project_code = project_code;
    // values.is_removed = true;
    //console.log(`deleting ${activeRowKey}`, values);
    deleteEmployeeItem([activeRowKey])
      .then(() => {
        const uservalue = userData?.find(
          (item: any) => item.employee_id === activeRowKey
        );
        deleteUserItem(uservalue._id)
          .then(() => {
            Emitter.emit("alert", {
              type: "success",
              message: "Employee has been successfully removed.",
              description: "",
              top: true,
              closeable: false,
              timeout: 3000,
            });
            resetForm();
            getLookupItem("employee").then((_data: any) => {
              if (_data) {
                let data = _data.map((item: any) => {
                  item.key = item._id;
                  item.value = item.fullname_nric;
                  item.label = item.fullname_nric;
                  return item;
                });
                data = sortData(data);
                setEmployees(data);
                setAllData({ ...allData, employees: data });
              }
            });
            navigate("/dashboard", { replace: true });
          })
          .catch(() => {
            Emitter.emit("alert", {
              type: "error",
              message: "Failed to delete employee. Please try again.",
              description: "",
              top: true,
              closeable: false,
              timeout: 3000,
            });
          })
          .finally(() => {
            const payload = {
              employee_id: activeRowKey,
            };
            socket.emit("pong-update-employee", payload, () => {
              console.log("Pinging server");
            });
          });
      })
      .catch(() => {
        Emitter.emit("alert", {
          type: "error",
          message: "Failed to delete employee. Please try again.",
          description: "",
          top: true,
          closeable: false,
          timeout: 3000,
        });
      })
      .finally(() => {
        // setActiveRowKey(null);
        Emitter.emit("finish-loading", null);
      });
  };

  // Handle form Cancel
  const handleFormCancel = () => {
    context?.handleFormUpdating(false);
    if (currentProject != "Add new employee") {
      // const project = allProjects.find((project: any) => {
      // 	return project.project_code == currentProject;
      // });
      // resetForm(project);
      resetForm(initialForm);
    } else {
      // console.log("cancel2");
      resetForm();
    }
  };

  const checkCode = () => {
    //console.log(initialForm);
    if (
      (initialForm?.business_unit !== formRef.getFieldsValue().business_unit ||
        initialForm?.client !== formRef.getFieldsValue().client) &&
      initialForm
    ) {
      return true;
    } else {
      return false;
    }
  };

  const extractDuplicateKeyValue = (errorDetail: string): string => {
    const match = errorDetail.match(/Duplicated key error:\s*(\w+)\s*:/);
    return match ? match[1] : "unknown";
  };

  //Set Content and form here
  const changeContent = () => {
    switch (tabkey) {
      case "personal":
        return (
          <>
            {/* {console.log("tabkey: ", tabkey)} */}
            <EmployeeConfiguration
              currentProject={currentProject}
              employeeType={employeeType}
              data={allData}
              positionData={positionData}
              formRef={formRef}
              activeRowKey={activeRowKey}
              endDateVisibility={endDateVisibility}
              setEndDateVisibility={setEndDateVisibility}
              setFormReset={setFormReset}
            />
            <div className="generic-footer" style={{ height: "50px" }}>
              {activeRowKey && (
                <Popconfirm
                  style={{
                    marginRight: "auto",
                    background: "#393939",
                  }}
                  overlayInnerStyle={{
                    maxWidth: "300px",
                    padding: "16px",
                  }}
                  placement="topLeft"
                  okText="Delete"
                  okType="danger"
                  title=""
                  icon={
                    <ExclamationCircleOutlined style={{ color: "orange" }} />
                  }
                  cancelText={<span>Cancel</span>}
                  overlayClassName="popconfirm-danger"
                  description="Permanently delete this employee? This action cannot be undone."
                  onOpenChange={(open: any) => {
                    if (!open) {
                      setEmptyModalOpen(false);
                    }
                  }}
                  onConfirm={() => {
                    setEmptyModalOpen(false);
                    handleFormDelete();
                  }}
                  onCancel={() => {
                    setEmptyModalOpen(false);
                  }}
                >
                  <Button
                    className="ant-btn-secondary"
                    style={{ marginRight: "auto" }}
                    danger
                  >
                    Delete
                  </Button>
                </Popconfirm>
              )}
              <FormButtonCancel
                form={formRef}
                handleFormCancel={handleFormCancel}
                formReset={formReset}
              ></FormButtonCancel>
              <Popover
                open={popOverVisibility}
                onOpenChange={(boolean: any) => {
                  if (checkCode()) {
                    setPopOverVisibility(boolean);
                  } else {
                    setPopOverVisibility(false);
                  }
                }}
                overlayStyle={{
                  maxWidth: "420px",
                  background: "rgba(57,57,57,1)",
                  borderRadius: "8px",
                  // padding: "16px",
                }}
                style={{ background: "transparent" }}
                trigger={"click"}
                content={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <Button
                      className="ant-button-default"
                      style={{
                        color: "white",
                        marginRight: "auto",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                      }}
                      ghost
                      onClick={() => {
                        setPopOverVisibility(false);
                        handleFormSave(null, "New");
                      }}
                    >
                      Create new
                    </Button>
                    <Button
                      className="ant-button-default"
                      style={{
                        color: "white",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                      }}
                      ghost
                      onClick={() => {
                        setPopOverVisibility(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="ant-button-default"
                      onClick={() => {
                        setPopOverVisibility(false);
                        handleFormSave(null, "code");
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                }
                title={
                  <Space>
                    <div style={{ color: "grey" }}>
                      {GetAntIcon("exclamation")}
                    </div>
                    <span style={{ fontWeight: "400" }}>
                      Changing the business unit or client info affects the
                      project code. Do you wish to proceed?
                    </span>
                  </Space>
                }
              >
                <div>
                  <FormButtonSave
                    form={formRef}
                    handleFormSave={handleFormSave}
                    formReset={formReset}
                  ></FormButtonSave>
                </div>
              </Popover>
            </div>
          </>
          // <ProjectDetails currentProject={currentProject} data={allData} />
        );
      case "leave":
        return (
          <>
            {" "}
            {activeRowKey && (
              <EmployeeLeave
                formRef={formRef}
                data={allData}
                activeRowKey={activeRowKey}
                leaverecordData={leaverecordData}
              />
            )}
          </>
        );
      case "resume":
        return (
          <>
            {/* <h1 style={{ color: "black" }}>hello world</h1> */}
            {(activeRowKey && (
              <EmployeeResume
                formRef={formRef}
                data={allData}
                activeRowKey={activeRowKey}
              />
            )) || (
              <div>
                <p style={{ color: "black" }}>
                  Please save employee data first.
                </p>
              </div>
            )}
            {/* <div
                            className="generic-footer"
                            style={{ height: "50px" }}
                        >
                            {activeRowKey && (
                                <Popconfirm
                                    style={{
                                        marginRight: "auto",
                                        background: "#393939",
                                    }}
                                    overlayInnerStyle={{
                                        maxWidth: "300px",
                                        padding: "16px",
                                    }}
                                    placement="topLeft"
                                    okText="Delete"
                                    okType="danger"
                                    title=""
                                    icon={
                                        <ExclamationCircleOutlined
                                            style={{ color: "orange" }}
                                        />
                                    }
                                    cancelText={<span>Cancel</span>}
                                    overlayClassName="popconfirm-danger"
                                    description="Permanently delete this employee? This action cannot be undone."
                                    onOpenChange={(open: any) => {
                                        if (!open) {
                                            setEmptyModalOpen(false);
                                        }
                                    }}
                                    onConfirm={() => {
                                        setEmptyModalOpen(false);
                                        handleFormDelete(activeRowKey);
                                    }}
                                    onCancel={() => {
                                        setEmptyModalOpen(false);
                                    }}
                                >
                                    <Button
                                        className="ant-btn-secondary"
                                        style={{ marginRight: "auto" }}
                                        danger
                                    >
                                        Delete
                                    </Button>
                                </Popconfirm>
                            )}
                            <FormButtonCancel
                                form={formRef}
                                handleFormCancel={handleFormCancel}
                                formReset={formReset}
                            ></FormButtonCancel>
                            <Popover
                                open={popOverVisibility}
                                onOpenChange={(boolean: any) => {
                                    if (checkCode()) {
                                        setPopOverVisibility(boolean);
                                    } else {
                                        setPopOverVisibility(false);
                                    }
                                }}
                                overlayStyle={{
                                    maxWidth: "420px",
                                    background: "rgba(57,57,57,1)",
                                    borderRadius: "8px",
                                    // padding: "16px",
                                }}
                                style={{ background: "transparent" }}
                                trigger={"click"}
                                content={
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px",
                                        }}
                                    >
                                        <Button
                                            className="ant-button-default"
                                            style={{
                                                color: "white",
                                                marginRight: "auto",
                                                border: "1px solid rgba(255, 255, 255, 0.1)",
                                            }}
                                            ghost
                                            onClick={() => {
                                                setPopOverVisibility(false);
                                                handleFormSave(null, "New");
                                            }}
                                        >
                                            Create new
                                        </Button>
                                        <Button
                                            className="ant-button-default"
                                            style={{
                                                color: "white",
                                                border: "1px solid rgba(255, 255, 255, 0.1)",
                                            }}
                                            ghost
                                            onClick={() => {
                                                setPopOverVisibility(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="ant-button-default"
                                            onClick={() => {
                                                setPopOverVisibility(false);
                                                handleFormSave(null, "code");
                                            }}
                                        >
                                            Proceed
                                        </Button>
                                    </div>
                                }
                                title={
                                    <Space>
                                        <div style={{ color: "grey" }}>
                                            {GetAntIcon("exclamation")}
                                        </div>
                                        <span style={{ fontWeight: "400" }}>
                                            Changing the business unit or client
                                            info affects the project code. Do
                                            you wish to proceed?
                                        </span>
                                    </Space>
                                }
                            >
                                <div>
                                    <FormButtonSave
                                        form={formRef}
                                        handleFormSave={handleFormSave}
                                        formReset={formReset}
                                    ></FormButtonSave>
                                </div>
                            </Popover>
                        </div> */}
          </>
        );

      // case "project-team":
      // 	return (
      // 		<ProjectConfiguration
      // 			handleProjectConfigSave={handleProjectConfigSave}
      // 			currentProject={currentProject}
      // 			resetForm={resetForm}
      // 			data={allData}
      // 		/>
      // 	);
      default:
        return <></>;
    }
  };

  return (
    <div className="generic-container">
      <Space direction="vertical">
        {/* <Select
					className="title-selector"
					style={{
						fontSize: "20px",
						fontWeight: "600",
						color: "rgba(255, 255, 255, 0.85)",
						width: "300px",
					}}
					// options={[...allProjects]}
					value={currentProject}
					open={dropdownOpen}
					onClick={() => {
						setDropdownOpen(!dropdownOpen);
					}}
					onChange={(key: any) => {
						handleProjectChange(key);
						setDropdownOpen(false);
					}}
					dropdownRender={(menu) => (
						<>
							{menu}
							<Divider style={{ margin: "8px 0" }} />
							<Space
								style={{
									width: "100%",
									justifyContent: "center",
								}}
							>
								<Button
									type="default"
									icon={GetAntIcon("plus")}
									onClick={() => {
										console.log("add new project");
										handleProjectChange("Add new project");
										setDropdownOpen(false);
									}}
									style={{
										width: "250px",
										maxWidth: "100%",
										marginBottom: "8px",
									}}
								>
									Add new project
								</Button>
							</Space>
						</>
					)}
				></Select> */}
        <Space direction="vertical">
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {currentEmployee}
          </span>
        </Space>
        <Tabs
          activeKey={tabkey}
          onChange={handleTabChange}
          items={[
            { label: "Personal", key: "personal" },
            { label: "Leave Balance", key: "leave" },
            // { label: "Contacts", key: "contacts" },
            // { label: "Payroll details", key: "payroll_details" },
            { label: "Resume", key: "resume" },
            // { label: "Accomplishment", key: "accomplishment" },
          ]}
        ></Tabs>
      </Space>
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "row",
          overflow: "auto",
        }}
      >
        <div
          className="main-container"
          style={{
            width: "100%", //"70%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "3px",
          }}
        >
          {changeContent()}
        </div>
        <div className="side-profile-container" style={{ flex: "1" }}></div>
      </div>

      <Modal
        className="empty-modal"
        open={emptyModalOpen}
        closable={false}
        maskClosable={true}
        onCancel={() => {
          setEmptyModalOpen(false);
        }}
        zIndex={1050}
        footer={<></>}
        style={{ background: "transparent" }}
      >
        <></>
      </Modal>
    </div>
  );
}

Projects.propTypes = {
  params: PropTypes.any,
};

export default Projects;
