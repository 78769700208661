/**
 * default exports for other modules to use
 */

import Emitter from "./emitter";

export const url = process.env.REACT_APP_SERVER_URL as string;

///// k-server routes to VM
export const serverConnection = url + `/dragonfly-dev`;
// export const serverConnection = `http://192.168.188.227:5000/api`;
// export const serverConnection = `http://10.188.188.227:5000/api`;
// export const serverConnection = `http://192.168.188.214:5000/api`;
// export const serverConnection = `http://localhost:5000/api`;

export const superAdminRole: any = process.env.REACT_APP_SUPER_ADMIN_TAG;
export let accessToken: any = localStorage.getItem(`accessToken`);
export const idToken: any = localStorage.getItem(`idToken`);
export let pbiToken: any = localStorage.getItem(`pbiToken`);
export let AuthHeader: any = {
    headers: {
        Authorization: accessToken,
    },
    withCredentials: true,
};

Emitter.on("refreshed", () => {
    accessToken = localStorage.getItem(`accessToken`);
    pbiToken = localStorage.getItem(`pbiToken`);
    AuthHeader = {
        headers: {
            Authorization: accessToken,
        },
        withCredentials: true,
    };
    // AuthHeader = AuthHeader;
});
