import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { serverConnection, url } from "../utils/_exports";
import {
  Button,
  Space,
  Table,
  Tabs,
  Tag,
  Dropdown,
  Modal,
  Form,
  Input,
  DatePicker,
  Upload,
  message,
  Divider,
  Select,
} from "antd";

import TextArea from "antd/es/input/TextArea";

import type { ColumnsType } from "antd/es/table";
import {
  getAddButtonProp,
  getColumnList,
  getLookupItem,
  getFormOptionList,
} from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { HomeContext } from "./Home";
import { useNavigate } from "react-router";
import { ConsoleSqlOutlined, UploadOutlined } from "@ant-design/icons";
import { updateLeaveItem, addLeaveItem } from "../services/api-server/leave";
import { uploadFile } from "../services/api-server/filetransfer";
import { updateEmployeeItem } from "../services/api-server/employee";
import { updateClaimBalance } from "../services/api-server/claimbalance";
import { daysCalculation } from "../utils/utils";
import { set, update } from "lodash";
import Item from "antd/es/list/Item";
import { v4 as uuidv4 } from "uuid";
import { calc } from "antd/es/theme/internal";
// import { LeaveConfiguration } from "./LeaveConfiguration";

import LeaveApprovalForm from "../components/LeaveApprovalForm";
import LeaveApplication from "./LeaveApplication";
import { color } from "d3";
import { OtherHouses } from "@mui/icons-material";
import moment from "moment";
import { sendEmail } from "../services/api-server/usertoken";
import { socket } from "../utils/socket";
import { updateDefaultClause } from "typescript";
import { isMobile } from "react-device-detect";

const LookupManager = (props: any) => {
  const context: any = useContext(HomeContext);
  const navigate: any = useNavigate();

  const role = props?.role;
  const userData = props?.userData;
  const [tabKey, setTabKey] = useState<any>(props.tabKey);
  const [subTabKey, setSubTabKey] = useState<any>(props.subTabKey || null);
  const containerTitle = props?.containerTitle;
  const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dbData, setDbData] = useState<any>({});

  const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
  const [sideContainerOpen, setSideContainerOpen] = useState<any>(
    props.sideContainerOpen || false
  );
  const [activeRowKey, setActiveRowKey] = useState<any>(
    props.activeRowKey || null
  );
  const [activeRecord, setActiveRecord] = useState<any>(
    props.activeRecord || null
  );
  const [expandedRowKeys, setExpandRowKey] = useState<any>([]);
  const [holidayData, setHolidayData] = useState<any>(
    props.holidayData || null
  );
  const [workdayData, setWorkdayData] = useState<any>(
    props.workdayData || null
  );
  const [positionData, setPositionData] = useState<any>([]);
  const [addButtonProp, setAddButtonProp] = useState<any>(null);
  const [searchRecord, setSearchRecord] = useState<any>([]);
  const [tempRecord, setTempRecord] = useState<any>(null);

  const [tableHeight, setTableHeight] = useState<any>(null);
  const [ready, setReady] = useState<any>(false);

  const [activeGroupKey, setActiveGroupKey] = useState<any>(null);

  const [showRejectionModal, setShowRejectionModal] = useState<any>(false);
  const [rejectionModalStatus, setRejectionModalStatus] = useState<any>("");
  const [rejectionReason, setRejectionReason] = useState<any>("");

  const [showLeaveApplication, setShowLeaveApplication] = useState<any>(false);
  const [leaveform] = Form.useForm();
  const [refreshData, setRefreshData] = useState<any>(false);

  const [imgNameUUIDList, setImgNameUUIDList] = useState<any>([]);

  const [openModal, setOpenModal] = useState<any>(true);

  const currentDate = new Date();

  const url = process.env.REACT_APP_SOCKET_SERVER as string;

  useEffect(() => {
    // positionData
    getLookupItem("position")
      .then((data: any) => {
        setPositionData(data);
      })
      .catch((error: any) => console.log(error));
  }, [tempRecord]);

  useEffect(() => {
    // console.log("tempRecord activated: ", tempRecord);
    if (searchRecord && tempRecord) {
      // console.log("11111", searchRecord, tempRecord);
      let recordIndex = searchRecord.findIndex((item: any) => {
        return item.columnKey === tempRecord.columnKey;
      });
      if (recordIndex !== -1) {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record[recordIndex] = tempRecord;
          setSearchRecord(_record);
        } else {
          let _record = [...searchRecord];
          _record.splice(recordIndex, 1);
          setSearchRecord(_record);
        }
      } else {
        if (tempRecord.value != "") {
          let _record = [...searchRecord];
          _record.push(tempRecord);
          setSearchRecord(_record);
        }
      }
    }
  }, [tempRecord]);

  useEffect(() => {
    refresh_employee();
    // console.log("searchRecord activated: ", searchRecord);
    if (searchRecord?.length > 0) {
      //console.log("Search record testing", searchRecord);
      let data: any = [...dataTable];

      // if (tabKey == "employee") {
      //     data = [...dataTable];
      // }

      let filteredData = data
        .map((element: any) => {
          let bool = searchRecord?.every((item: any) => {
            if (item.columnKey === "leave_date") {
              const filteredDates = element["date_range"]?.filter(
                (date: any) => {
                  const dateObject = new Date(date);
                  const day = String(dateObject.getDate()).padStart(2, "0"); // Get day with leading zero
                  const month = String(dateObject.getMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Get month with leading zero
                  const year = String(dateObject.getFullYear()); // Get full year

                  // Check if any part of the date contains "04"
                  return (
                    day.includes(item.value) ||
                    month.includes(item.value) ||
                    year.includes(item.value) ||
                    moment(dateObject)
                      .format("DD MMM YYYY")
                      .toLowerCase()
                      .includes(item.value.toLowerCase())
                  );
                }
              );
              return filteredDates.length > 0; // Return true if there are dates containing the specified value
            } else {
              return element[item.columnKey]
                ?.toString()
                ?.toLowerCase()
                .includes(item.value.toLowerCase());
            }
          });
          if (bool) return element;
        })
        .filter((element: any) => element !== null && element !== undefined);
      setFilteredDataTable(filteredData);
    } else {
      setFilteredDataTable(null);
    }
  }, [searchRecord, refreshData]);

  const handleSearch = (record: any) => {
    setTempRecord(record);
  };

  const handleTabChange = async (key: any) => {
    try {
      await context.handlePageChange();
      setTableColumns([]);
      setDataTable([]);
      setSideContainerOpen(false);
      setSubTabKey(key);
      setExpandRowKey([]);
      setActiveRowKey(null);
      setActiveGroupKey(null);
    } catch {}
  };

  const calculateTableHeight = () => {
    const table: any = document.getElementById("table-container");
    if (table) {
      setTableHeight(table?.clientHeight - 65 - 64);
    }
  };

  const handleApproval = (values: any) => {
    values.status = "approved";
    values.denial_reason = "";
    values.closed_date = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    //console.log("Received values of form:", values);

    const employee_db_id = dbData["employee_id"].find(
      (item: any) => item._id == values.employee_id
    )?._id;
    const employee_data: any = dbData["employee_id"].find(
      (item: any) => item._id == values.employee_id
    );

    // const workingDays: any = daysCalculation(
    //   workdayData,
    //   holidayData,
    //   values.start_date,
    //   values.end_date,
    //   values.half_days
    // );

    // const currentYear = new Date().getFullYear();
    // const applicationStartYear = new Date(values.start_date).getFullYear();
    // const applicationEndYear = new Date(values.end_date).getFullYear();
    // for (const leave of employee_data.leave_entitled) {
    //   if (
    //     currentYear === applicationStartYear &&
    //     currentYear === applicationEndYear
    //   ) {
    //     if (leave.leave_type === values.leave_type) {
    //       leave.used += workingDays;
    //       leave.pending = leave.pending - workingDays;
    //     }
    //   }
    // }

    // // **Update the claim balance based on the requested claim amount**
    // const requestedClaimAmount = values.requestedclaim; // Assuming `requested_claim` holds the amount the user wants to claim
    // // console.log(values.requestedclaim);
    // const currentClaimBalance = employee_data.claims; // Retrieve the current claim balance
    // // console.log(employee_data.claims);

    // // Update the claim balance
    // if (requestedClaimAmount > 0) {
    //   employee_data.claims = currentClaimBalance - requestedClaimAmount;
    //   // employee_data.claims = employee_data.claimbalance; // Deduct the requested claim amount from the current balance
    // }

    // delete values.duration;
    delete values.date_range;
    delete values.leave_type_code;
    delete values.leave_type_name;
    // employee_data.leave_entitled[values.leave_type].used += workingDays;

    // daysCalculation(values.start_date, values.end_date).then((workingDays: any) => {
    //     employee_data.leave_entitled[values.leave_type].used += workingDays;
    // });
    // employee_data.leave_entitled[values.leave_type].used += values.days;
    const content = `Dear ${employee_data.fullname_nric},\n\nYour leave application has been ${values.status}. Please view your leave application's status at ${url}\n\nThank you.\n\nBest regards,\nRajang Digital Solutions HR Team`;
    console.log("value", values);
    updateLeaveItem([activeRowKey], values)
      .then((_data) => {
        // Success handler for updating leave item
        delete employee_data.content;
        delete employee_data.receiver;
        delete employee_data.leave_type_code;
        delete employee_data.leave_type_name;
        const workingDays: any = daysCalculation(
          workdayData,
          holidayData,
          values.start_date,
          values.end_date,
          values.half_days
        );

        const currentYear = new Date().getFullYear();
        const applicationStartYear = new Date(values.start_date).getFullYear();
        const applicationEndYear = new Date(values.end_date).getFullYear();
        for (const item of employee_data.leave_entitled) {
          if (
            currentYear === applicationStartYear &&
            currentYear === applicationEndYear
          ) {
            if (item.leave_type === values.leave_type) {
              if (item.leave_entitled !== 0) {
                item.pending = item.pending - workingDays;
                item.used = item.used + workingDays;
              }
            }
          }
        }
        return Promise.all([
          updateEmployeeItem([employee_db_id], employee_data), // Update the employee record with the new claim balance
          // updateClaimBalance(employee_db_id, employee_data.claims), // Update the claim balance in the database
        ]);
      })
      .then((_data) => {
        // Emit the event after both updates have been completed
        Emitter.emit("alert", {
          type: "success",
          title: "Success",
          message:
            "Leave application " +
            values.leave_id_num +
            " approved. Sending email...",
          description: "",
          top: true,
          closeable: false,
          timeout: 3000,
        });
        Emitter.emit("USER_HAS_BEEN_UPDATED", employee_data.email);
      })
      .catch((error) => {
        // Error handler for any of the operations
        //console.log("Error:", error);
      })
      .finally(() => {
        // Additional cleanup or actions
        setSideContainerOpen(false);
        setActiveRowKey(null);
        setRefreshData(!refreshData);
        setActiveGroupKey(null);
        sendEmail(employee_data, content, [employee_data.email])
          .then((response: any) => {
            // Handle successful email sending
            console.log("Email sent successfully:", response);
          })
          .catch((error: any) => {
            // Handle errors
            // console.error("Error sending email:", error);
          });
        socket.emit("pong-update", () => {
          // const duration = Date.now() - start;
          console.log("Pinging server");
        });
      });
  };

  const RejectionModal = () => {
    const handleOk = () => {
      //console.log("Received rejection reason", rejectionReason);
      if (rejectionReason) {
        const values = activeRecord;
        values.status = rejectionModalStatus;
        values.closed_date = new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        values.denial_reason = rejectionReason;
        //console.log("Received values of form:", values);

        const employee_db_id = dbData["employee_id"].find(
          (item: any) => item._id == values.employee_id
        )?._id;
        const employee_data = dbData["employee_id"].find(
          (item: any) => item._id == values.employee_id
        );
        // if (rejectionReason)
        //     for (const item of employee_data.leave_entitled) {
        //         if (item.leave_type === values.leave_type) {
        //             item.used -= daysCalculation(
        //                 workdayData,
        //                 holidayData,
        //                 values.start_date,
        //                 values.end_date,
        //                 values.half_days
        //             );
        //         }
        //     }
        // employee_data.leave_entitled[values.leave_type].used -=
        //     daysCalculation(
        //         workdayData,
        //         holidayData,
        //         values.start_date,
        //         values.end_date,
        //         values.half_days
        //     );
        const content = `Dear ${employee_data.fullname_nric},\n\nYour leave application has been ${values.status} due to the following reason:\n\n${values.denial_reason}.\n\nPlease view your leave application's status at ${url}\n\nThank you.\n\nBest regards,\nRajang Digital Solutions HR Team`;
        delete values.content;
        delete values.receiver;
        updateLeaveItem([activeRowKey], values)
          .then((_data: any) => {
            // if (values.status === "cancelled") {
            const workingDays: any = daysCalculation(
              workdayData,
              holidayData,
              values.start_date,
              values.end_date,
              values.half_days
            );

            const currentYear = new Date().getFullYear();
            const applicationStartYear = new Date(
              values.start_date
            ).getFullYear();
            const applicationEndYear = new Date(values.end_date).getFullYear();
            for (const item of employee_data.leave_entitled) {
              if (
                currentYear === applicationStartYear &&
                currentYear === applicationEndYear
              ) {
                if (item.leave_type === values.leave_type) {
                  if (item.entitled !== 0) {
                    item.pending = item.pending - workingDays;
                  }
                }
              }
            }

            delete employee_data.content;
            delete employee_data.receiver;
            delete employee_data.leave_type_code;
            delete employee_data.leave_type_name;
            // **Update the claim balance based on the requested claim amount**
            const requestedClaimAmount = values.requestedclaim; // Assuming `requested_claim` holds the amount the user wants to claim
            // console.log(values.requestedclaim);
            const currentClaimBalance = employee_data.claims; // Retrieve the current claim balance
            // console.log(employee_data.claims);

            // Update the claim balance
            if (requestedClaimAmount > 0) {
              employee_data.claims = currentClaimBalance + requestedClaimAmount;
              // employee_data.claims = employee_data.claimbalance; // Deduct the requested claim amount from the current balance
            }
            return updateEmployeeItem([employee_db_id], employee_data);
            // }
          })
          .then((_data: any) => {
            Emitter.emit("alert", {
              type: "success",
              title: "Success",
              message:
                values.leave_id_num +
                " Leave application " +
                rejectionModalStatus +
                ". Sending email...",
              description: "",
              top: true,
              closeable: false,
              timeout: 3000,
            });
          })
          .catch((error: any) => {
            //console.log("error", error);
          })
          .finally(() => {
            setShowRejectionModal(false);
            setSideContainerOpen(false);
            setActiveRowKey(null);
            setActiveGroupKey(null);
            setRejectionReason("");
            setRefreshData(!refreshData);
            sendEmail(employee_data, content, [employee_data.email])
              .then((response: any) => {
                // Handle successful email sending
                console.log("Email sent successfully:", response);
              })
              .catch((error: any) => {
                // Handle errors
                // console.error("Error sending email:", error);
              });
            socket.emit("pong-update", () => {
              console.log("Pinging server");
            });
            // setExpandRowKey([]);
            // setDataTable([]);
            // setTableColumns([]);
            // setTabKey("leaveManager");
          });
      } else {
        // show an error message
        Emitter.emit("alert", {
          type: "error",
          title: "Error",
          message: "Please enter a reason for rejection.",
          description: "",
          top: true,
          closeable: false,
          timeout: 3000,
        });
      }
    };

    const handleCancel = () => {
      // Close the modal without performing any action
      setShowRejectionModal(false);
    };

    return (
      <Modal
        // title="Reason for Rejection"
        title={
          rejectionModalStatus == "rejected"
            ? "Reason for Rejection"
            : "Reason for Cancellation (This action can't be undone)"
        }
        open={showRejectionModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !rejectionReason }}
        className="rejection-modal"
      >
        <Input.TextArea
          placeholder={
            rejectionModalStatus == "rejected"
              ? "Enter reason for rejection..."
              : "Enter reason for cancellation..."
          }
          onChange={(e) => setRejectionReason(e.target.value)}
          value={rejectionReason}
        />
      </Modal>
    );
  };

  const setSideContainer = () => {
    if (sideContainerOpen) {
      return (
        <div
          className="lookup-side-container"
          style={{
            width: isMobile ? "100%" : "35%",
            maxHeight: "100%",
            // margin: "10px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            borderRadius: "3px",
          }}
        >
          {/* <Modal
          open={openModal}
          width={isMobile ? "100%" : "35%"}
          style={{ top: 20, borderRadius: "3px" }}
          closable={false}
          footer={null}
        > */}
          <div className="lookup-side-header" style={{ height: "50px" }}>
            <span
              style={{
                marginRight: "auto",
                minHeight: "32px",
                display: "flex",
                alignItems: "center",
                color: "#ffffff",
              }}
            >
              {/* {tabKey.charAt(0).toUpperCase() +
                                tabKey.split("_").join(" ").slice(1)}{" "} */}
              Leave details
            </span>
            <button
              onClick={() => {
                setSideContainerOpen(false);
                setActiveRowKey(null);
                setActiveGroupKey(null);
              }}
              style={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "15px",
                // fontWeight: "600",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>
          <div
            className="generic-content"
            style={{
              flex: "1",
              gap: "0px",
              maxHeight: "100%",
              overflow: "auto",
              flexWrap: "nowrap",
              backgroundColor: "#ffffff",
              border: "solid 1px",
              borderLeftColor: "#A7A7A7",
              borderRightColor: "#A7A7A7",
            }}
          >
            {sideContainerOpen && (
              <LeaveApprovalForm
                setTabKey={setTabKey}
                data={activeRecord}
                tabKey={tabKey}
                subTabKey={subTabKey}
                holidayData={holidayData}
                workdayData={workdayData}
              />
            )}
          </div>
          <div className="generic-footer" style={{ height: "50px" }}>
            {activeRecord.status == "pending" && (
              <div>
                <Button
                  onClick={() => {
                    setRejectionModalStatus("rejected");
                    setShowRejectionModal(true);
                  }}
                  className="ant-btn-secondary"
                >
                  Reject
                </Button>{" "}
                <Button
                  onClick={() => {
                    handleApproval(activeRecord);
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Approve
                </Button>
              </div>
            )}
            {activeRecord.status == "approved" &&
              currentDate < new Date(activeRecord.start_date) && (
                <div>
                  <Button
                    onClick={() => {
                      setRejectionModalStatus("cancelled");
                      setShowRejectionModal(true);
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Cancel Leave
                  </Button>
                </div>
              )}
            {(activeRecord.status == "rejected" ||
              activeRecord.status == "cancelled" ||
              currentDate > new Date(activeRecord.start_date)) &&
              activeRecord.status !== "pending" && (
                <div>
                  <Button
                    onClick={() => {
                      setSideContainerOpen(false);
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Close
                  </Button>
                </div>
              )}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    refresh_employee();
    getLookupItem("workday").then((workday_data: any) => {
      getLookupItem("holiday").then((holiday_data: any) => {
        setHolidayData(holiday_data);
        setWorkdayData(workday_data);
      });
    });
  }, []);

  const refresh_employee = () => {
    Promise.all([getLookupItem("employee")]).then((data: any) => {
      const object: any = {};
      object["employee_id"] = data[0];
      setDbData(object);
    });
  };

  const handleLeaveApplication = () => {
    const formList = getFormOptionList(tabKey, "configuration");

    // const handleFinish = () => {
    //     setShowLeaveApplication(false);
    //     leaveform.resetFields();
    //     setImgNameUUIDList([]);
    //     setRefreshData(!refreshData);
    // };

    return (
      <LeaveApplication
        role={role}
        userData={userData}
        // activeRowKey={activeRowKey}
        showLeaveApplication={showLeaveApplication}
        setShowLeaveApplication={setShowLeaveApplication}
        formList={formList}
        leaveform={leaveform}
        imgNameUUIDList={imgNameUUIDList}
        setImgNameUUIDList={setImgNameUUIDList}
        workdayData={workdayData}
        holidayData={holidayData}
        dbData={dbData["employee_id"]}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        // setInputType={setInputType}
        // handleFinish={handleFinish}
        // handleCancel={handleCancel}
        // handleReset={handleReset}
      />
    );
  };

  const fetchData = () => {
    setReady(false);
    window.addEventListener("resize", () => {
      calculateTableHeight();
    });
    Emitter.emit("loading", null);
    let columns: any = getColumnList(tabKey, handleSearch, subTabKey);
    setAddButtonProp(getAddButtonProp(tabKey));

    const itemKey =
      tabKey === "employee"
        ? "employee"
        : tabKey === "leaveManager"
        ? "leave"
        : tabKey;

    getLookupItem(itemKey)
      .then((_data: any) => {
        let data: any = [];
        let key = columns[0].dataIndex;
        if (tabKey == "employee") {
          // console.log(_data);
          _data = _data?.map((item: any) => {
            const position = positionData?.find(
              (position_item: any) =>
                position_item.position_code === item.position
            );
            // console.log(item.position);
            delete item.position;
            return {
              ...item,
              position: position ? position.position_name : "-",
            };
          });

          if (subTabKey == "employee") {
            data = _data?.filter(
              (item: any) =>
                item.position != "Intern" &&
                item.position !== "INTERN" &&
                (item.status === "active" || item.status === undefined)
            );
          } else if (subTabKey == "intern") {
            data = _data?.filter(
              (item: any) =>
                item.position === "Intern" &&
                (item.status === "active" || item.status === undefined)
            );
          } else if (subTabKey == "archived") {
            data = _data?.filter((item: any) => item.status === "inactive");
          }
        } else if (tabKey == "leaveManager") {
          if (subTabKey == "all_leave") {
            data = _data;
          } else if (subTabKey == "pending") {
            data = _data.filter((item: any) => item.status == "pending");
          } else if (subTabKey == "approved") {
            data = _data.filter((item: any) => item.status == "approved");
          } else if (subTabKey == "rejected") {
            data = _data.filter((item: any) => item.status == "rejected");
          } else if (subTabKey == "cancelled") {
            data = _data.filter((item: any) => item.status == "cancelled");
          } else {
            data = _data;
          }
        } else {
          data = _data;
        }

        if (data) {
          data = data
            .map((element: any) => {
              return {
                ...element,
              };
            })
            .sort((a: any, b: any) => a[key].localeCompare(b[key]));

          data = tabKey === "leaveManager" ? data.reverse() : data;
          //console.log(data);
          if (tabKey === "leaveManager") {
            data = data.map((element: any) => {
              let duration = "0";
              if (element.days === 0 || !element.days) {
                duration = "0 day";
              } else {
                const duration_in_days: any = daysCalculation(
                  workdayData,
                  holidayData,
                  element.start_date,
                  element.end_date,
                  element.half_days
                );
                duration =
                  duration_in_days <= 1
                    ? `${duration_in_days} day`
                    : `${duration_in_days} days`;
              }

              return { ...element, duration: duration };
            });
          }

          // console.log("1-data11", data);
          let renderedColumns = [];

          if (columns) {
            renderedColumns = columns.map((element: any) => {
              if (element.isActive == true) {
                element.render = (text: any, record: any) => {
                  const id = record._id || record.uuid || record.group_uuid;
                  const employee_name = record.fullname_nric;
                  if (text) {
                    return (
                      <a
                        onClick={() => {
                          // console.log(record);
                          if (tabKey == "employee") {
                            navigate("configuration", {
                              replace: false,
                              state: {
                                tabKey,
                                subTabKey,
                                activeRowKey: id,
                                name: employee_name,
                              },
                            });
                          } else if (tabKey == "leaveManager") {
                            // console.log(
                            //     "showModal active"
                            // );
                            setSideContainerOpen(true);
                            setActiveRowKey(id);
                            setActiveRecord(record);
                            setHolidayData(holidayData);
                            setWorkdayData(workdayData);
                          }
                        }}
                        className="table-clickable-text"
                      >
                        {text}
                      </a>
                    );
                  }
                };
              }

              return element;
            });
            setTableColumns(renderedColumns);
            setDataTable(data);
          }
        } else {
          setTableColumns([]);
          setDataTable([]);
        }
      })
      .catch((e: any) => {
        //console.log(e);
        setTableColumns([]);
        setDataTable([]);
      })
      .finally(() => {
        setReady(true);
        calculateTableHeight();
        setFilteredDataTable(null);
        setSearchRecord([]);
        setTempRecord(null);
        Emitter.emit("finish-loading", null);
      });
  };

  useEffect(() => {
    // fetchData();
    // socket.on("refresh-leave-data", () => {
    //     fetchData();
    // });
    fetchData(); // Initial data fetch

    const handleRefresh = () => fetchData();
    socket.on("refresh-leave-data", handleRefresh);

    // Cleanup function
    return () => {
      socket.off("refresh-leave-data", handleRefresh);
    };
  }, [tabKey, subTabKey, refreshData, holidayData, workdayData]);

  const getSubTabKeys = () => {
    if (tabKey == "employee") {
      return [
        {
          label: "Employee",
          key: "employee",
        },
        {
          label: "Intern",
          key: "intern",
        },
        {
          label: "Archived",
          key: "archived",
        },
      ];
    } else if (tabKey == "leaveManager") {
      return [
        {
          label: "All Leave",
          key: "all_leave",
        },
        {
          label: "Pending",
          key: "pending",
        },
        {
          label: "Approved",
          key: "approved",
        },
        {
          label: "Rejected",
          key: "rejected",
        },
        {
          label: "Cancelled",
          key: "cancelled",
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        maxHeight: "100%",
        overflow: "hidden",
      }}
    >
      <div
        className="main-container"
        style={{
          width: "100%",
          maxHeight: "100%",
          maxWidth: "100%", //70%
          overflow: "hidden",
          display: sideContainerOpen && isMobile ? "none" : "flex",
          flexDirection: "column",
          borderRadius: "3px",
        }}
      >
        <div className="generic-header" style={{ height: "50px" }}>
          {/* Configurations */}
          {/* {tabKey.charAt(0).toUpperCase() + tabKey.slice(1)} */}
          {containerTitle}
        </div>
        <div
          className="generic-content"
          style={{
            flex: "1",
            gap: "0px",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            flexWrap: "nowrap",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Tabs
            style={{ width: isMobile ? "100%" : "auto" }}
            tabPosition={isMobile ? "top" : "left"}
            size={isMobile ? "small" : "large"}
            onChange={handleTabChange}
            activeKey={subTabKey}
            items={getSubTabKeys()}
          ></Tabs>
          <div
            style={{
              flex: "1",
              height: "100%",
              display: "flex",
              maxWidth: "100%",
              overflow: "hidden",
              flexDirection: "column",
            }}
          >
            {" "}
            <div
              className="generic-table-header"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <span
                style={{
                  marginRight: "auto",
                  minHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {tabKey == "employee" &&
                  subTabKey.charAt(0).toUpperCase() +
                    subTabKey.split("_").join(" ").slice(1)}{" "}
                {tabKey == "leaveManager" &&
                  subTabKey.charAt(0).toUpperCase() +
                    subTabKey.split("_").join(" ").slice(1)}{" "}
                directory
              </span>

              {/* {addButtonProp ? (
								<Dropdown
									disabled={sideContainerOpen}
									menu={{
										items: addButtonProp,
										onClick: (ev: any) => {
											navigate("configuration", {
												replace: false,
												state: { tabKey },
											});
											// resetForm();
											// setFormOption(ev.key);
											// setActiveRowKey(false);
											// setSideContainerOpen(true);
										},
									}}
									placement="bottomLeft"
								>
									<Button>+ Add</Button>
								</Dropdown>
							) : (
								<Button
									onClick={() => {
										navigate("configuration", {
											replace: false,
											state: { tabKey, name: "Add new employee" },
										});
									}}
									// style={tabKey == "country" ? { display: "none" } : {}}
									disabled={sideContainerOpen}
								>
									+ Add
								</Button>
							)} */}
              {tabKey == "employee" &&
                (subTabKey === "employee" || subTabKey === "intern") && (
                  <Button
                    onClick={() => {
                      navigate("configuration", {
                        replace: false,
                        state: {
                          tabKey,
                          subTabKey,
                          name: `Add new ${subTabKey}`,
                        },
                      });
                    }}
                    // style={tabKey == "country" ? { display: "none" } : {}}
                    disabled={sideContainerOpen}
                  >
                    + Add
                  </Button>
                )}
              {tabKey == "leaveManager" && subTabKey == "all_leave" && (
                <Button
                  onClick={() => {
                    switch (tabKey) {
                      case "employee":
                        navigate("configuration", {
                          replace: false,
                          state: {
                            tabKey,
                            subTabKey,
                            name: `Add new ${subTabKey}`,
                          },
                        });
                        break;
                      case "leaveManager":
                        setShowLeaveApplication(true);
                        handleLeaveApplication();
                        break;
                      default:
                        break;
                    }
                  }}
                  // style={tabKey == "country" ? { display: "none" } : {}}
                  // disabled={sideContainerOpen}
                >
                  + Add
                </Button>
              )}
            </div>
            <div
              id="table-container"
              style={{
                flex: 1,
                display: "flex",
                maxHeight: "100%",
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              {ready && (
                <Table
                  rowClassName={(record: any) =>
                    record?.uuid === activeRowKey ||
                    `${record.group_uuid}-${record.tag_uuid}` ===
                      activeGroupKey ||
                    (record.group_uuid === activeRowKey && !record.uuid)
                      ? "antd-table-row-active"
                      : ""
                  }
                  pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: [20, 50, 100],
                    showSizeChanger: true,
                    position: ["bottomCenter"],
                  }}
                  columns={tableColumns}
                  expandable={{
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expand: any, record: any) => {
                      let keys = [...expandedRowKeys];
                      if (expand) {
                        keys.push(record.uuid);
                      } else {
                        let index = keys.findIndex(
                          (item: any) => item == record.uuid
                        );
                        if (index != -1) {
                          keys.splice(index, 1);
                        }
                      }
                      setExpandRowKey(keys);
                    },
                  }}
                  scroll={
                    tableHeight && {
                      x: isMobile ? "100vw" : "100vw",
                      y: tableHeight,
                      // x: "0px",
                    }
                  }
                  dataSource={filteredDataTable || dataTable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {setSideContainer()}
      {RejectionModal()}

      {handleLeaveApplication()}
      {/* {LeaveConfiguration()} */}
      {/* <LeaveApplication /> */}
    </div>
    // </div>
  );
};

// LookupManager.propTypes = {
// 	params: PropTypes.any,
// };

export default LookupManager;
// function setCurrent(arg0: () => any) {
//     throw new Error("Function not implemented.");
// }
