import React, { useContext, useEffect, useState } from "react";
import { Space, Tabs, Typography } from "antd";
import { HomeContext } from "./Home";
import { getLookupItem } from "../utils/lookup_list";
import { pdfjs } from "react-pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { setupPdfJsWorker } from "../utils/pdfjs-worker-setup";
import { isMobile } from "react-device-detect";
import { fetchFile } from "../services/api-server/filetransfer";
import moment from "moment";

const { Text, Title } = Typography;

function AnnouncementContainer(props: any) {
  const context: any = useContext(HomeContext);
  const [tabKey, setTabKey] = useState<any>("");
  const [tabKeys, setTabKeys] = useState([]);
  const [holidayData, setHolidayData] = useState<any>([]);
  const [workdayData, setWorkdayData] = useState<any>([]);
  const [pdfURL, setPdfURL] = useState<any>(null);
  const [last_updated, setLast_updated] = useState<any>(null);
  const [announcementList, setAnnouncementList] = useState([]);

  const role = props.params.userRole[0];
  const userData = props.params.user;

  useEffect(() => {
    setupPdfJsWorker();
    getLookupItem("workday").then((workday_data: any) => {
      getLookupItem("holiday").then((holiday_data: any) => {
        setHolidayData(holiday_data);
        setWorkdayData(workday_data);
      });
    });
    getLookupItem("announcement").then((announcementData: any) => {
      if (announcementData) {
        setAnnouncementList(announcementData);
        announcementData = announcementData?.sort((a: any, b: any) =>
          a["announcement_name"].localeCompare(b["announcement_name"])
        );
        setTabKeys(
          announcementData?.map((data: any) => {
            return {
              label: data?.announcement_name,
              key: data?.announcement_name,
            };
          })
        );
        setTabKey(announcementData[0].announcement_name);
        setLast_updated(
          moment(new Date(announcementData[0].last_updated)).format(
            "DD MMM YYYY"
          )
        );
        fetchFile(
          announcementData[0].filename,
          announcementData[0].uuid,
          "announcement_files"
        ).then((url: any) => {
          // console.log(url);
          setPdfURL(url);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (tabKey && announcementList) {
      const data: any = announcementList?.find(
        (item: any) => item.announcement_name === tabKey
      );

      if (data?.filename && data?.uuid) {
        fetchFile(data.filename, data.uuid, "announcement_files").then(
          (url: any) => {
            setPdfURL(url);
            setLast_updated(
              moment(new Date(data.last_updated)).format("DD MMM YYYY")
            );
          }
        );
      }
    }
  }, [tabKey, announcementList]);

  const changeContent = () => {
    const newplugin: any = defaultLayoutPlugin();
    return (
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: "83%",
          width: "100%",
        }}
      >
        <div
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            height: "100%",
            width: "100%",
          }}
        >
          {pdfURL && (
            <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>
              <Viewer fileUrl={pdfURL} plugins={[newplugin]}></Viewer>
            </Worker>
          )}
          <div
            style={{
              color: "black",
              fontSize: "20px",
              textAlign: "center",
              padding: "15%",
              display: !pdfURL ? "" : "none",
            }}
          >
            {!pdfURL && (
              <Title level={2}>Announcements have not been uploaded</Title>
            )}
          </div>
        </div>
        <div
          style={{
            width: isMobile ? "100%" : "30%",
            paddingLeft: isMobile ? "" : "20px",
          }}
        >
          {last_updated && (
            <Text italic style={{ fontSize: isMobile ? "" : "20px" }}>
              Announcement last updated on: {last_updated}
            </Text>
          )}
        </div>
      </div>
    );
  };

  const handleTabChange = async (key: any) => {
    try {
      await context.handlePageChange();
      setTabKey(key);
    } catch {}
  };

  return (
    <>
      <div className="generic-container" style={{ gap: "20px" }}>
        <Space direction="vertical">
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "rgba(0, 0, 0, 0.85)",
            }}
          >
            {props.params.propTitle}
          </span>
        </Space>

        <Tabs
          onChange={handleTabChange}
          activeKey={tabKey}
          items={tabKeys}
        ></Tabs>

        {changeContent()}
      </div>
    </>
  );
}

export default AnnouncementContainer;
